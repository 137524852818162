/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class Sportello extends Component {
  render() {
    const { settings } = this.props;
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1 id="page-title">{settings.nome_sportello_web}</h1>
        </PageTitle>
        <PageContent style={{ marginTop: "-25px" }}>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(Sportello);
