/**
 * External Dependencies
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: localStorage.getItem("homepage"),
    };
  }

  componentDidMount() {
    if (this.state.redirect != "/") {
      let redirect = !this.state.redirect ? "/" : this.state.redirect;
      this.props.history.push(redirect);
    }
  }

  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>HomePage</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default withRouter(HomePage);
