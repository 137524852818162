/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageContent from "../../../../components/page-content";
import PageTitle from "../../../../components/page-title";
import PageWrap from "../../../../components/page-wrap";

/**
 * Component
 */
class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Caricamento...",
    };
  }

  loadTitle() {
    let totemId = this.props.match.params.totem;
    if (totemId === undefined) {
      this.props.history.push("/");
    }
    window.api.totem
      .pageInfo(totemId)
      .then((res) => {
        let page = res.data.pageInfo;
        this.setState({
          title: page.titolo,
        });
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  }

  componentDidMount() {
    this.loadTitle();
  }

  render() {
    const { title } = this.state;

    return (
      <PageWrap>
        <PageTitle>
          <h1 id="page-title">{title}</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(withRouter(Success));
