/**
 * Internal Dependencies
 */
// Auth
import QuattroCentoTre from "./403";
import AuthSignIn from "./AuthSignIn";
import AuthSSO from "./AuthSSO";

//Sportelli
import AmbulatorialeCode from "./AmbulatorialeCode";
import AmbulatorialeCodeFull from "./AmbulatorialeCodeFull";
import AmbulatorialeLight from "./AmbulatorialeLight";
import Grafici from "./Grafici";
import HomePage from "./HomePage";
import Informativa from "./Informativa";
import Monitoraggio from "./Monitoraggio";
import Sportello from "./Sportello";
import Statistiche from "./Statistiche";

//Amministratore
import Ambienti from "./Ambienti";
import Code from "./Code";
import Display from "./Display";
import Impostazioni from "./Impostazioni";
import Media from "./Media";
import Monitor from "./Monitor";
import LayoutMonitor from "./Monitor/Layout";
import Permessi from "./Permessi";
import Policy from "./Policy";
import Prestazioni from "./Prestazioni";
import Privacy from "./Privacy";
import Scanner from "./Scanner";
import Schedulazioni from "./Schedulazioni";
import Sportelli from "./Sportelli";
import Ticket from "./Ticket";
import Totem from "./Totem";
import TotemBottoni from "./Totem/Bottoni";
import LayoutTotem from "./Totem/Layout";
import Utenti from "./Utenti";

//No Auth
import AuthReset from "./AuthReset";
import Display_Live from "./NoAuth/Display";
import Monitor_Live from "./NoAuth/Monitor";
import Totem_Live from "./NoAuth/Totem";
import Error from "./NoAuth/Totem/Error";
import Success from "./NoAuth/Totem/Success";
import Playlist from "./Playlist";
import PlaylistEdit from "./PlaylistEdit";

export default {
  //Auth
  "/sign-in": AuthSignIn,
  "/sso-token": AuthSSO,
  "/403": QuattroCentoTre,

  //Strumenti
  "/": HomePage,
  "/informativa": Informativa,
  "/web/:id?": Sportello,
  "/ambulatoriale-light/:id?": AmbulatorialeLight,
  "/ambulatoriale-code/:id?": AmbulatorialeCode,
  "/ambulatoriale-code-full/:id?": AmbulatorialeCodeFull,
  "/monitoraggio": Monitoraggio,
  "/grafici": Grafici,
  "/statistiche": Statistiche,

  //Amministratore
  "/ambienti": Ambienti,
  "/code": Code,
  "/totem": Totem,
  "/t-bottoni": TotemBottoni,
  "/t-layout": LayoutTotem,
  "/t-ticket": Ticket,
  "/monitor": Monitor,
  "/m-layout": LayoutMonitor,
  "/display": Display,
  "/sportelli": Sportelli,
  "/policy": Policy,
  "/prestazioni": Prestazioni,
  "/privacy": Privacy,
  "/scanner": Scanner,
  "/schedulazioni": Schedulazioni,
  "/playlist": Playlist,
  "/playlist/edit": PlaylistEdit,
  "/playlist/edit/:id": PlaylistEdit,
  "/media": Media,
  "/utenti": Utenti,
  "/permessi": Permessi,
  "/impostazioni": Impostazioni,

  //No Auth
  "/monitor-l/:id": Monitor_Live,
  "/display-l/:id": Display_Live,
  "/totem-l/:id": Totem_Live,
  "/success/:totem?": Success,
  "/error/:totem?/:code?": Error,
  "/reset-password/:token": AuthReset,
};
