/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";

/**
 * Internal Dependencies
 */
import Icon from "../../../../components/icon";
import ColorPicker from "../../../../components/layout-builder/color-picker";

/**
 * Component
 */
class ModalSchedulazione extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  toggle() {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Le modifiche effettuate non verranno salvate",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.dismiss();
        this.setState({
          modalOpen: false,
        });
      }
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  handleChildUnmount() {
    this.setState({ renderChildColoreS: false });
  }

  submitForm = (e) => {
    e.preventDefault();
    let fuori_schedulazione = {
      testoS: e.target.testoS.value,
      dimensioneS: e.target.dimensioneS.value,
      coloreS: this.state.coloreS,
    };

    this.props.setFuoriSchedulazione(fuori_schedulazione);
    this.dismiss();
  };

  setColor = (hex) => {
    this.setState({
      coloreS: hex,
    });
  };

  loadPage() {
    let fuori_schedulazione = this.props.fuori_schedulazione;
    if (fuori_schedulazione) {
      for (let [key, value] of Object.entries(fuori_schedulazione)) {
        this.setState({
          [`${key}`]: value,
        });
      }
    }
  }

  componentDidMount() {
    this.loadPage();
  }

  render() {
    const { testoS, coloreS, dimensioneS } = this.state;

    return (
      <Fragment>
        {this.state.renderChildColoreS ? (
          <ColorPicker
            unmountMe={this.handleChildUnmount}
            setColor={this.setColor}
            selectedColor={coloreS}
            componente="Testo fuori schedulazione"
          />
        ) : null}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="md"
        >
          <div className="modal-header">
            <h5 className="modal-title h2">Testo fuori schedulazione</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Form id="editorForm" onSubmit={this.submitForm}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="testoS">Testo</Label>
                    <Input
                      required
                      value={testoS}
                      type="text"
                      name="testoS"
                      id="testoS"
                      placeholder="Testo"
                      onChange={(e) =>
                        this.setState({ testoS: e.target.value })
                      }
                    />
                    <Label for="dimensioneS">Dimensione</Label>
                    <Input
                      required
                      value={dimensioneS}
                      type="text"
                      name="dimensioneS"
                      id="dimensioneS"
                      placeholder="Dimensione in rem"
                      onChange={(e) =>
                        this.setState({ dimensioneS: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <Label for="coloreS">Colore Testo:</Label>
                  <Button
                    id="coloreS"
                    style={{ float: "center" }}
                    Button
                    size="lg"
                    color="brand"
                    className="btn-hover-outline btn-uniform btn-round"
                    onClick={() => this.setState({ renderChildColoreS: true })}
                  >
                    <Icon name="palette" vendor="tabler" />
                  </Button>
                </Row>
              }
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="editorForm" color="brand">
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(ModalSchedulazione);
