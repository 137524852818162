import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import LayoutBuilder from "../../../../components/layout-builder";

class FormLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: undefined,
      tabs: [],
      contents: [],
      index: 1,
      activeTab: "pagina_1",
      nome: "",
      loading: true,
    };
  }

  toggleTab(name) {
    this.setState({
      activeTab: name,
    });
  }

  salvaLayout() {
    if (!this.state.nome) {
      window.swal.fire({
        title: "Nome layout assente",
        text: "Inserire un nome per il layout",
        icon: "warning",
      });
      return;
    }
    if (!this.state.layout || !Object.keys(this.state.layout).length) {
      window.swal.fire({
        title: "Nessuna modifica effettuata",
        text: "Non hai inserito nessuna modifica layout",
        icon: "warning",
      });
      return;
    }

    for (const [key, column] of Object.entries(this.state.layout.columns)) {
      if (column.tipo === "media" && !column.playlist) {
        window.swal.fire({
          title: "Nessuna playlist selezionata",
          text: "Seleziona una playlist per poter salvare il layout",
          icon: "warning",
        });
        return;
      }
    }

    if (this.props.type == 1) {
      window.api.monitor.layout
        .aggiungi({
          layout: this.state.layout,
          layout_name: this.state.nome,
        })
        .then((res) => {
          this.props.status(0);
          window.swal.fire({
            title: "Layout Salvato",
            icon: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.api.monitor.layout
        .modifica({
          layout_id: this.props.id,
          layout: this.state.layout,
          layout_name: this.state.nome,
        })
        .then((res) => {
          this.props.status(0);
          window.swal.fire({
            title: "Layout Salvato",
            icon: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  setLayout = (layout) => {
    this.setState({
      layout: layout,
    });
  };

  loadLayout() {
    window.api.monitor.layout
      .dati(this.props.id)
      .then((res) => {
        let layout = res.data.layout;
        let nome = res.data.nome;

        this.setState({
          nome: nome,
          layout: layout,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    if (this.props.type == 2) {
      this.loadLayout();
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { nome, salva, layout, loading } = this.state;

    return (
      <>
        <Row>
          <Col lg="2">
            <FormGroup style={{ marginTop: "15px" }}>
              <Label for="nome">Nome</Label>
              <Input
                onChange={(e) => this.setState({ nome: e.target.value })}
                required
                defaultValue={nome}
                type="text"
                name="nome"
                id="nome"
                placeholder="Inserire Nome Layout"
              />
            </FormGroup>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <FormGroup style={{ marginTop: "50px" }}>
              <Button
                className="welcome-custom-btn"
                onClick={() => this.salvaLayout()}
                color="brand"
              >
                Salva
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        {!loading ? (
          <LayoutBuilder
            tipologia="Monitor"
            layout={layout}
            setLayout={this.setLayout}
            closeForm={this.closeForm}
            salvaLayout={salva}
            nome={nome}
            type={this.props.type}
            id={1}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default connect(
  ({ sidenav }) => ({
    sidenav,
  }),
  {}
)(withRouter(FormLayout));
