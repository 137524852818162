/**
 * Styles
 */
import "./style.scss";

/**
 * External Dependencies
 */
import classnames from "classnames/dedupe";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

/**
 * Internal Dependencies
 */
import { initPluginYaybar } from "../../../assets/js/rootui-parts/initPluginYaybar";
import "../../../assets/js/yaybar/yaybar";
import { showError } from "../../utils";
import Icon from "../icon";

import { updateAuth as actionUpdateAuth } from "../../actions";

window.RootUI.initPluginYaybar = initPluginYaybar;

/**
 * Component
 */
class PageYaybar extends Component {
  constructor(props) {
    super(props);
    this.state = { permessi: undefined };
    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    window.RootUI.initPluginYaybar();
    this.caricaPermessi();
  }

  logOut() {
    const { updateAuth } = this.props;

    window.api.auth
      .logout()
      .then((res) => {
        if (res.status == 201) {
          updateAuth({
            token: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  caricaPermessi() {
    window.api.auth
      .permessi()
      .then((res) => {
        this.setState({
          permessi: res.data.permessi,
        });
      })
      .catch((err) => {
        showError(err);
      });
  }

  renderSubmenus(nav, returnObject = false) {
    const { permessi } = this.state;

    if (permessi != undefined) {
      let thereIsActive = false;

      const result = Object.keys(nav).map((url) => {
        if (permessi.filter((e) => e.url === "path::" + url).length > 0) {
          const data = nav[url];
          const isActive = window.location.pathname === url;
          let isOpened = false;
          if (window.location.pathname == "/web" && data.name == "Homepage") {
            return null;
          }
          if (isActive) {
            thereIsActive = true;
          }

          let sub = "";
          if (data.sub) {
            const subData = this.renderSubmenus(data.sub, true);

            sub = (
              <ul className="yay-submenu dropdown-triangle">
                {subData.content}
              </ul>
            );

            if (subData.thereIsActive) {
              isOpened = true;
              thereIsActive = true;
            }
          }

          return (
            <React.Fragment key={`${url}-${data.name}`}>
              {data.label ? <li className="yay-label">{data.label}</li> : ""}
              <li
                className={classnames({
                  "yay-item-active": isActive,
                  "yay-submenu-open": isOpened,
                })}
              >
                {data.name ? (
                  <NavLink
                    to={data.sub || isOpened ? "#" : url}
                    className={data.sub ? "yay-sub-toggle" : ""}
                  >
                    {data.icon ? (
                      <>
                        <span className="yay-icon">
                          <Icon name={data.icon} vendor="tabler" />
                        </span>
                        <span>{data.name}</span>
                        <span className="rui-yaybar-circle" />
                      </>
                    ) : (
                      data.name
                    )}
                    {data.sub ? (
                      <span className="yay-icon-collapse">
                        <Icon
                          name="chevron-right"
                          strokeWidth="1"
                          className="rui-icon-collapse"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                ) : (
                  ""
                )}
                {sub}
              </li>
            </React.Fragment>
          );
        }
      });

      if (returnObject) {
        return {
          content: result,
          thereIsActive,
        };
      }

      return result;
    }
  }

  render() {
    const { settings } = this.props;

    return (
      <>
        <div
          className={classnames(
            "yaybar rui-yaybar yay-hide-to-small yay-gestures",
            settings.sidebar_dark && !settings.night_mode
              ? "rui-yaybar-dark"
              : "",
            settings.sidebar_static ? "yay-static" : "",
            settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : ""
          )}
        >
          <div className="yay-wrap-menu">
            <div className="yaybar-wrap">
              <ul>
                {this.renderSubmenus(settings.navigation_sidebar)}

                <li className="yay-label">Account</li>
                <li>
                  <NavLink to="/sign-in" onClick={this.logOut}>
                    <span className="yay-icon">
                      <Icon name="logout" vendor="tabler" />
                    </span>
                    <span>Esci</span>
                    <span className="rui-yaybar-circle"></span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="rui-yaybar-bg" />
      </>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings,
  }),
  { updateAuth: actionUpdateAuth }
)(PageYaybar);
