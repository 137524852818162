/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import ReactColor from "../react-color";

/**
 * Internal Dependencies
 */
import Icon from "../icon";
/* import Carousel from './carousel'; */

/**
 * Component
 */
class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.dismiss();
    this.setState({
      modalOpen: false,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  setColorPagina = (hex) => {
    this.setState(
      {
        colore_sfondo_pagina: hex,
      },
      () => this.props.setColor(hex)
    );
  };

  render() {
    const { selectedColor, componente } = this.props;

    const {} = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          size="sm"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title h2">Colore {componente}</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <ReactColor
                setColor={this.setColorPagina}
                color={selectedColor ? selectedColor : undefined}
              />
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(ColorPicker);
