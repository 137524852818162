import Swal from "sweetalert2";

/**
 * SweetAlert Docs: https://sweetalert2.github.io
 * This wrapper is useful to centralize some configuration for the alerts.
 * @param {Object} options - Alert configuration options
 * @param {String} options.title - Alert title
 * @param {String} [options.icon] - Possible values: "success", "warning", "error", "info", "question"
 * @param {Number} [options.timer] - Auto close timer in milliseconds
 * @param {String} [options.html] - HTML content for the alert
 * @param {Boolean} [options.showConfirmButton] - Whether to show the confirm button
 * @param {String} [options.confirmButtonText] - Text for the confirm button
 * @param {Boolean} [options.heightAuto] - Whether to auto-adjust height
 * @param {String|Number} [options.width] - Width of the alert
 * @returns {Promise<SweetAlertResult>}
 */

export const defaultTotemPopupConfig = {
  didOpen: (options) => {
    // Get the popup element
    const modal = Swal.getPopup();
    const contentHeight = window.innerHeight * 0.9;
    
    // Get heights of title, icon, and footer (if they exist)
    const title = modal.querySelector('.swal2-title');
    const icon = modal.querySelector('.swal2-icon');
    const footer = modal.querySelector('.swal2-footer');
    const actions = modal.querySelector('.swal2-actions');
    
    // Calculate their heights
    const titleHeight = title ? title.offsetHeight : 0;
    const iconHeight = icon ? icon.offsetHeight : 0;
    const footerHeight = footer ? footer.offsetHeight : 0;
    const actionsHeight = actions ? actions.offsetHeight : 0;
    
    // Calculate available space for content with extra padding for small screens
    const extraPadding = window.innerHeight <= 800 ? 60 : 40;
    const availableHeight = contentHeight - titleHeight - iconHeight - footerHeight - actionsHeight - extraPadding;
    
    // Set the content height
    const content = modal.querySelector('.swal2-html-container');
    if (content) {
      content.style.maxHeight = `${availableHeight}px`;
      content.style.height = `${availableHeight}px`;
      
      // Also set height for image container if it exists
      const imageContainer = content.querySelector('.image-container');
      if (imageContainer) {
        const textElement = content.querySelector('p');
        const textHeight = textElement ? textElement.offsetHeight : 0;
        const padding = window.innerHeight <= 800 ? 30 : 20;
        
        imageContainer.style.height = `${availableHeight - textHeight - padding}px`;
        
        // Find and adjust the image itself
        const image = imageContainer.querySelector('img');
        if (image) {
          image.style.maxHeight = '80%';
          image.style.maxWidth = '100%';
        }
      }
    }
    
    // Set confirm button size
    const confirmButton = modal.querySelector('.swal2-confirm');
    if (confirmButton) {
      confirmButton.style.transform = 'scale(1.3)';
      confirmButton.style.margin = '1rem';
    }
    
    // Call the original didOpen if it exists
    if (options.didOpen) {
      options.didOpen(popup);
    }
  },
  timer: 5000,
  width: "100%",
  showConfirmButton: true,
  confirmButtonText: "CHIUDI",
};

const showAlertMessage = (options) => {
  const defaultConfig = {
    allowEscapeKey: true,
    allowOutsideClick: true,
  };

  return window.swal.fire({
    ...defaultConfig,
    ...options,
  });
};

/**
 * 
 * @param {String} title
 * @param {Number} seconds
 * @returns {Promise<SweetAlertResult>}
 */
export const showSocketConnectionRetryAlertMessage = (title, seconds) => {
    let timerInterval;
    return Swal.fire({
      title: title,
      html: `Nuovo tentativo di connessione in <b>${seconds}</b> secondi.`,
      timer: seconds*1000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Math.round(
            Swal.getTimerLeft() / 1000,
            0
          )}`;
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
}

export default showAlertMessage;