import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import LayoutBuilder from "../../../../components/layout-builder";
import Tabs from "../../../../components/tabs";

import Icon from "../../../../components/icon";
import ModalSchedulazione from "./modal-schedulazione";
import StampaPopup from "./stampa-popup";

class FormLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: [],
      tabs: [],
      contents: [],
      index: 1,
      activeTab: "pagina_1",
      nome: "",
    };

    this.handleChildUnmount = this.handleChildUnmount.bind(this);
  }

  toggleTab(name) {
    this.setState({
      activeTab: name,
    });
  }

  salvaLayout() {
    if (this.state.nome != "") {
      if (this.state.layout.length !== 0 && this.state.layout !== undefined) {
        if (this.props.type == 1) {
          window.api.totem.layout
            .aggiungi({
              layout: this.state.layout,
              layout_name: this.state.nome,
              stampa_popup: this.state.stampaPopup,
              fuori_schedulazione: this.state.fuori_schedulazione,
            })
            .then((res) => {
              this.props.status(0);
              window.swal.fire({
                title: "Layout Aggiunto",
                icon: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          window.api.totem.layout
            .modifica({
              layout_id: this.props.id,
              layout: this.state.layout,
              layout_name: this.state.nome,
              stampa_popup: this.state.stampaPopup,
              fuori_schedulazione: this.state.fuori_schedulazione,
            })
            .then((res) => {
              this.props.status(0);
              window.swal.fire({
                title: "Layout Modificato",
                icon: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        window.swal.fire({
          title: "Nessuna modifica effettuata",
          text: "Non hai inserito nessuna modifica layout",
          icon: "warning",
        });
      }
    } else {
      window.swal.fire({
        title: "Nome layout assente",
        text: "Inserire un nome per il layout",
        icon: "warning",
      });
    }
  }

  setPopup = (params) => {
    this.setState({
      stampaPopup: JSON.stringify(params),
    });
  };

  setLayout = (layout, pagina) => {
    this.setState((prevState) => ({
      layout: { ...prevState.layout, [`pagina_${pagina}`]: layout },
    }));
  };

  setFuoriSchedulazione = (fuori_schedulazione) => {
    this.setState({
      fuori_schedulazione: JSON.stringify(fuori_schedulazione),
    });
  };

  aggiungiTab() {
    let new_index = parseInt(this.state.index) + 1;
    let contents = this.state.contents;
    contents.push(
      <Tabs.Pane id={"content_" + new_index} tabId={"pagina_" + new_index}>
        <LayoutBuilder
          pagine={this.state.pagine}
          setLayout={this.setLayout}
          id={new_index}
          tipologia="Totem"
        />
      </Tabs.Pane>
    );

    this.setState(
      {
        index: new_index,
        tabs: [
          ...this.state.tabs,
          <Tabs.NavItem
            id={"tab_" + new_index}
            isActive={this.state.activeTab === "pagina_" + new_index}
            onClick={() => this.toggleTab("pagina_" + new_index)}
          >
            <a
              className="page-tab"
              style={{ color: "black !important" }}
              href="/"
              onClick={() =>
                this.rimuoviTab("tab_" + new_index, "content_" + new_index)
              }
            >
              X
            </a>
            &nbsp;Pagina #{new_index}
          </Tabs.NavItem>,
        ],
        contents: contents,
      },
      () => {
        this.toggleTab("pagina_" + new_index);
      }
    );
  }

  rimuoviTab(tabId, contentId) {
    let updatedTabs = this.state.tabs.filter((obj) => obj.props.id !== tabId);
    let updatedContent = this.state.contents.filter(
      (obj) => obj.props.id !== contentId
    );
    //remove key from layout
    let layout = this.state.layout;
    delete layout["pagina_" + tabId.split("_")[1]];
    let new_index = this.state.new_index - 1;
    this.setState(
      {
        new_index: new_index,
        tabs: updatedTabs,
        contents: updatedContent,
        layout: layout,
      },
      () => this.toggleTab("pagina_1")
    );
  }

  loadLayout() {
    window.api.totem.layout
      .dati(this.props.id)
      .then((res) => {
        let layout = res.data.layout;
        let nome = res.data.nome;
        let stampaPopup = res.data.stampaPopup;
        let fuori_schedulazione = res.data.fuori_schedulazione;
        this.setState({
          layout: layout,
          stampaPopup: stampaPopup,
          fuori_schedulazione: fuori_schedulazione,
          action: stampaPopup === "null" ? "Inserisci" : "Modifica",
        });
        let pagine = [];
        for (let [i, l] of Object.entries(this.state.layout)) {
          let index = i.split("_")[1];
          let pagina = {
            index: index,
            name: l.nome_pagina,
          };
          pagine.push(pagina);
        }
        this.setState({ pagine: pagine });

        for (let [i, l] of Object.entries(layout)) {
          let index = i.split("_")[1];
          let contents = this.state.contents;
          contents.push(
            <Tabs.Pane id={"content_" + index} tabId={i}>
              <LayoutBuilder
                pagine={this.state.pagine}
                setLayout={this.setLayout}
                layout={l}
                id={index}
                tipologia="Totem"
              />
            </Tabs.Pane>
          );

          this.setState(
            {
              nome: nome,
              index: index,
              tabs: [
                ...this.state.tabs,
                <Tabs.NavItem
                  id={"tab_" + index}
                  isActive={this.state.activeTab === i}
                  onClick={() => this.toggleTab(i)}
                >
                  {pagine.length > 1 ? (
                    <a
                      className="page-tab"
                      style={{ color: "black !important" }}
                      href="/"
                      onClick={() =>
                        this.rimuoviTab("tab_" + index, "content_" + index)
                      }
                    >
                      X
                    </a>
                  ) : (
                    <></>
                  )}
                  &nbsp;{" "}
                  {l.nome_pagina && l.nome_pagina.length > 0
                    ? l.nome_pagina
                    : "Pagina"}{" "}
                  [{index}]
                </Tabs.NavItem>,
              ],
              contents: contents,
            },
            () => {}
          );
        }
      })
      .catch((err) => {
        console.log(err);
        //showError(err);
      });
  }

  handleChildUnmount() {
    this.setState({
      renderChild: false,
      renderChildSchedulazione: false,
    });
  }

  componentDidMount() {
    if (this.props.type == 2) {
      this.loadLayout();
    }
  }

  render() {
    const { nome, tabs, fuori_schedulazione, contents, salva } = this.state;

    return (
      <>
        {this.state.renderChildSchedulazione ? (
          <ModalSchedulazione
            unmountMe={this.handleChildUnmount}
            fuori_schedulazione={
              fuori_schedulazione ? JSON.parse(fuori_schedulazione) : undefined
            }
            setFuoriSchedulazione={this.setFuoriSchedulazione}
          />
        ) : null}
        {this.state.renderChild ? (
          <StampaPopup
            unmountMe={this.handleChildUnmount}
            action={this.state.action}
            editor={
              this.state.stampaPopup
                ? JSON.parse(this.state.stampaPopup)
                : undefined
            }
            setPopup={this.setPopup}
          />
        ) : null}
        <Row>
          <Col lg="2">
            <FormGroup style={{ marginTop: "15px" }}>
              <Label for="nome">Nome</Label>
              <Input
                onChange={(e) => this.setState({ nome: e.target.value })}
                required
                defaultValue={nome}
                type="text"
                name="nome"
                id="nome"
                placeholder="Inserire Nome Layout"
                maxLength="64"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <FormGroup>
              <Label for="url">
                Popup Stampa:
                <Button
                  onClick={() =>
                    this.setState({
                      renderChild: true,
                    })
                  }
                  size="sm"
                  color="brand"
                  className="btn-hover-outline btn-uniform btn-round"
                >
                  <Icon name="edit" vendor="tabler" />
                </Button>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label for="url">
                Testo fuori schedulazione:
                <Button
                  onClick={() =>
                    this.setState({
                      renderChildSchedulazione: true,
                    })
                  }
                  size="sm"
                  color="brand"
                  className="btn-hover-outline btn-uniform btn-round"
                >
                  <Icon name="calendar" vendor="tabler" />
                </Button>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Tabs sliding>
          {this.props.type != 2 ? (
            <Tabs.NavItem
              isActive={this.state.activeTab === "pagina_1"}
              onClick={() => this.toggleTab("pagina_1")}
            >
              Pagina #1
            </Tabs.NavItem>
          ) : (
            <></>
          )}
          {tabs}
          <Tabs.NavItem onClick={() => this.aggiungiTab()}>
            <Icon name="plus" vendor="tabler" />
          </Tabs.NavItem>
        </Tabs>

        <Tabs.Content activeTab={this.state.activeTab}>
          {this.props.type != 2 ? (
            <Tabs.Pane tabId="pagina_1">
              <LayoutBuilder
                tipologia="Totem"
                pagine={this.state.pagine}
                setLayout={this.setLayout}
                closeForm={this.closeForm}
                salvaLayout={salva}
                nome={nome}
                type={this.props.type}
                id={1}
              />
            </Tabs.Pane>
          ) : (
            <></>
          )}
          {contents.map((c) => {
            return c;
          })}
        </Tabs.Content>
        <Col style={{ textAlign: "right" }}>
          <FormGroup>
            <Button
              className="welcome-custom-btn"
              onClick={() => this.salvaLayout()}
              color="brand"
            >
              {this.props.type ? "Salva" : "Modifica"}
            </Button>
          </FormGroup>
        </Col>
      </>
    );
  }
}

export default connect(
  ({ sidenav }) => ({
    sidenav,
  }),
  {}
)(withRouter(FormLayout));
