import React, { Component } from "react";
import PageContent from "./components/page-content";
import PageTitle from "./components/page-title";
import PageWrap from "./components/page-wrap";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    const errore = { error: error, errorInfo: errorInfo };
    window.api.error
      .log(errore)
      .then((res) => {
        console.log("Errore registrato");
      })
      .catch(() => {
        console.log("Impossibile contattare il server");
      });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Error path
    return hasError ? (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>Oops!</h1>
        </PageTitle>
        <PageContent>
          <p className="display-4 mb-30">Qualcosa è andato storto</p>
        </PageContent>
      </PageWrap>
    ) : (
      // Normally, just render children
      children
    );
  }
}

export default ErrorBoundary;
