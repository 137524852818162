import React, { Component } from "react";
import { Row } from "reactstrap";

import ColumnButton from "./column-button";

class ButtonBody extends Component {
  constructor(props) {
    super(props);

    this.elaborateButtons = this.elaborateButtons.bind(this);
  }

  elaborateButtons(bottoni, indiceColonna) {
    this.props.setButtons(bottoni, indiceColonna);
  }

  componentDidMount() {
    this.elaborateButtons();
  }

  render() {
    const { buttons, twoColumns } = this.props;

    let colonne = [];
    let elenco = [];

    if (!twoColumns) {
      let merged = [];
      for (let col of buttons) {
        for (let b of col) {
          if (!merged.find((el) => el.id == b.id)) {
            merged.push(b);
          }
        }
      }
      elenco.push(merged);
    } else {
      elenco = buttons;
      if (buttons.length == 0) {
        elenco = [[], []];
      } else if (buttons.length == 1) {
        elenco.push([]);
      }
    }

    for (let colonna of elenco) {
      colonne.push(
        <ColumnButton
          elencoBottoni={colonna}
          pagine={this.props.pagine}
          tipologia={this.props.tipologia}
          setButtons={this.elaborateButtons}
          indice={buttons.indexOf(colonna)}
        />
      );
    }

    return (
      <>
        <div
          className="btn-item"
          style={{
            borderLeft: "1px solid black",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            height: "100%",
          }}
        >
          <Row style={{ height: "100%", width: "100%" }}>{colonne}</Row>
        </div>
      </>
    );
  }
}
export default ButtonBody;
