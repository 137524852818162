/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";

/**
 * Internal Dependencies
 */
import Icon from "../../../../components/icon";
import ModalMedia from "../../Bottoni/components/modal-media";

/**
 * Component
 */
class StampaPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  toggle() {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Le modifiche effettuate non verranno salvate",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.dismiss();
        this.setState({
          modalOpen: false,
        });
      }
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  setMedia = (media) => {
    this.setState({
      immaginePopup: media,
    });
  };

  handleChildUnmount() {
    this.setState({ renderChild: false });
  }

  submitForm = (e) => {
    e.preventDefault();
    let pagina = {
      messaggioPopup: e.target.messaggioPopup.value,
      immaginePopup: this.state.immaginePopup,
    };
    this.props.setPopup(pagina);
    this.dismiss();
  };

  async risolviMedia(nome_file) {
    return new Promise((resolve, reject) => {
      window.api.media
        .file(nome_file, "Immagine")
        .then((res) => {
          const file = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const fileURL = URL.createObjectURL(file);

          resolve(fileURL);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  loadPage() {
    let media = null;
    if (this.props.editor !== undefined && this.props.editor !== null) {
      if (this.props.editor.immaginePopup !== undefined) {
        this.risolviMedia(this.props.editor.immaginePopup[0].name).then(
          (fileURL) => {
            media = this.props.editor.immaginePopup;
            media[0].url = fileURL;
            this.setState({
              immaginePopup: media,
            });
          }
        );
      }
      this.setState({
        messaggioPopup: this.props.editor.messaggioPopup,
      });
    }
  }

  componentDidMount() {
    this.loadPage();
  }

  render() {
    const { action } = this.props;

    const { messaggioPopup, immaginePopup } = this.state;

    return (
      <Fragment>
        {this.state.renderChild ? (
          <ModalMedia
            title="Immagine"
            tipologia="Totem"
            unmountMe={this.handleChildUnmount}
            immaginePopup={this.state.immaginePopup}
            setMedia={this.setMedia}
          />
        ) : null}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="md"
        >
          <div className="modal-header">
            <h5 className="modal-title h2">{action} Popup Stampa</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Form id="editorForm" onSubmit={this.submitForm}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="messaggioPopup">Messaggio</Label>
                    <Input
                      required
                      value={messaggioPopup}
                      type="text"
                      name="messaggioPopup"
                      id="messaggioPopup"
                      placeholder="Messaggio"
                      onChange={(e) =>
                        this.setState({ messaggioPopup: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {immaginePopup !== undefined ? (
                <Row>
                  <Col>
                    <div style={{ position: "relative" }}>
                      <img
                        style={{ width: "100%" }}
                        src={immaginePopup[0].url}
                        alt="immagine pagina"
                      />
                      <Button
                        color="brand"
                        className="btn btn-brand btn-uniform btn-round btn-sm"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() =>
                          this.setState({ immaginePopup: undefined })
                        }
                      >
                        <Icon name="trash" vendor="tabler" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Button
                      color="brand"
                      className="btn btn-brand btn-uniform btn-round btn-sm"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                      onClick={() => this.setState({ renderChild: true })}
                    >
                      <Icon name="photo" vendor="tabler" />
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="editorForm" color="brand">
              {action}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(StampaPopup);
