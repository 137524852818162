import moment from "moment";
import Swal from "sweetalert2";

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

/**
 * Check if email is valid.
 *
 * @param {string} email email string.
 *
 * @return {boolean} is valid.
 */
export function isValidEmail(email) {
  // eslint-disable-next-line
  const reg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

export async function isValidCF(cf) {
  // eslint-disable-next-line
  const reg =
    /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/;
  return reg.test(cf);
}

/**
 * Get unique ID.
 *
 * @return {string} uid.
 */
export function getUID() {
  return (
    Number(String(Math.random()).slice(2)) +
    Date.now() +
    Math.round(window.performance.now())
  ).toString(36);
}

/**
 * Convert file size to human readable string.
 *
 * @param {number} size file size.
 *
 * @return {string} human readable size.
 */
export function fileSizeToHumanReadable(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

function titleCase(str) {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function alertMissing(key) {
  let field = key.replace("_", " ");
  Swal.fire(
    "Oops!",
    "Campo " + titleCase(field) + " mancante o incompleto.",
    "error"
  );
}

export async function serializeData(data, optional_fields = null) {
  let body = {};
  for (let [key, value] of data.entries()) {
    if (optional_fields) {
      if (optional_fields.indexOf(key) < 0) {
        if (value == undefined || value == "" || value == "undefined") {
          alertMissing(key);
          return {};
        }
      }
    }
    body[`${key}`] = value;
  }
  return body;
}

export function showError(err) {
  let errore = "Oops!";
  let erroreStr = "Ricontrolla l'operazione effettuata";
  let icon = "error";
  if (err.response) {
    switch (err.response.status) {
      case 422:
        errore = "Uno o più parametri mancanti";
        erroreStr = "Per favore controlla i parametri e riprova.";
        icon = "warning";
        break;
      case 409:
        erroreStr = err.response.data?.error || "Un altro utente sta già gestendo questa risorsa. Per favore riprova tra qualche momento.";
        break;
      default:
        errore = err.response.data.error || "Errore sconosciuto";
        erroreStr = err.response.data.dettaglio || "Si è verificato un errore imprevisto. Per favore riprova.";
        break;
    }
  } else {
    erroreStr = err;
  }
  window.swal.fire({
    title: errore,
    text: erroreStr,
    icon: icon,
  });
}

export function isStringified(str) {
  try {
    return JSON.parse(str);
  } catch (err) {
    return false;
  }
}

export function selectToArray(select) {
  if (select != undefined) {
    let final_select = [];
    for (let s of select) {
      final_select.push(s.value);
    }
    return JSON.stringify(final_select);
  }
  return "";
}

export function valueToSelect(value, select) {
  let selectedElement = undefined;
  if (select != undefined) {
    selectedElement = select.find((el) => el.value == value);
  }
  return selectedElement;
}

export async function getFirstDayOfMonth(month) {
  let date = new Date();
  let FirstDay = new Date(date.getFullYear().toString(), month);

  return FirstDay;
}

export async function getLastDayOfMonth(month) {
  let date = new Date();
  let LastDay = new Date(date.getFullYear().toString(), month + 1, 0);

  return LastDay;
}

export async function checkTicketsExpire(tickets) {
  return new Promise((resolve, reject) => {
    for (let t of tickets) {
      t.expired = { soglia1: false, soglia2: false };
      if (t.soglia1 != -1 && t.soglia2 != -1) {
        let expire_date_soglia1 = moment(
          t.data_inserimento,
          "DD/MM/YYYY HH:mm:ss"
        ).add(t.soglia1, "minutes");
        let expire_date_soglia2 = moment(
          t.data_inserimento,
          "DD/MM/YYYY HH:mm:ss"
        ).add(t.soglia2, "minutes");
        let now = moment();

        let check_expire1 = now.isSameOrAfter(expire_date_soglia1);
        let check_expire2 = now.isSameOrAfter(expire_date_soglia2);

        if (check_expire1) {
          if (check_expire2) {
            t.expired = { soglia1: true, soglia2: true };
            continue;
          }
          t.expired = { soglia1: true, soglia2: false };
        }
      }
    }
    resolve(tickets);
  });
}

export async function caricaFeedRss(url) {
  return new Promise((resolve) => {
    let body = {
      url: url,
    };
    window.api.rss
      .getFeed(body)
      .then((res) => {
        resolve(res.data.feed);
      })
      .catch((err) => {
        showError(err);
      });
  });
}
