/**
 * External Dependencies
 */
import classnames from "classnames/dedupe";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

/**
 * Internal Dependencies
 */
import { initNavbar } from "../../../assets/js/rootui-parts/initNavbar";
import {
  updateAuth as actionUpdateAuth,
  updateSettings as actionUpdateSettings,
} from "../../actions";
import { showError } from "../../utils";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

/**
 * Component
 */
class PageNavbar extends Component {
  constructor(props) {
    super(props);

    window.RootUI.initNavbar();

    this.state = {
      mobileMenuShow: false,
      username: "Sportello",
    };

    this.logOut = this.logOut.bind(this);
    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
  }

  componentDidMount() {
    $(document).on("keydown.rui-navbar", (e) => {
      const { mobileMenuShow } = this.state;

      if (mobileMenuShow && e.keyCode === 27) {
        this.setState({
          mobileMenuShow: !mobileMenuShow,
        });
      }
    });
    this.loadUsername();
  }

  componentWillUnmount() {
    $(document).off("keydown.rui-navbar");
  }

  loadUsername() {
    window.api.auth
      .username()
      .then((res) => {
        this.setState({
          username: res.data.username,
        });
      })
      .catch((err) => {
        showError(err);
      });
  }

  logOut() {
    const { updateAuth } = this.props;

    window.api.auth
      .logout()
      .then((res) => {
        if (res.status == 201) {
          updateAuth({
            token: "",
          });
        }
      })
      .catch((err) => {
        showError(err);
      });
  }

  renderSubmenus(nav, isMobile, level = 1) {
    return Object.keys(nav).map((url) => {
      const data = nav[url];

      const isActive = window.location.pathname === url;

      const LinkContent = data.name ? (
        <>
          {data.icon ? (
            <>
              <Icon name={data.icon} vendor="tabler" />
              <span>{data.name}</span>
              <span
                className={data.sub ? "rui-dropdown-circle" : "rui-nav-circle"}
              />
            </>
          ) : (
            data.name
          )}
        </>
      ) : (
        ""
      );

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.sub ? (
            <Dropdown
              tag="li"
              className={classnames(isActive ? "active" : "")}
              direction={level === 1 ? "up" : "right"}
              openOnHover={!isMobile}
              showTriangle
            >
              <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                {LinkContent}
              </Dropdown.Toggle>
              <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                {this.renderSubmenus(data.sub, isMobile, level + 1)}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <li className={classnames("nav-item", isActive ? "active" : "")}>
              <Link to={data.sub ? "#" : url} className="nav-link">
                {LinkContent}
              </Link>
            </li>
          )}
        </React.Fragment>
      );
    });
  }

  openInformativa() {
    const { updateAuth } = this.props;

    window.api.auth
      .getPolicy()
      .then((res) => {
        let { title, body } = res.data.informativa;
        window.swal.fire({
          title: title,
          html: body,
          icon: "info",
        });
      })
      .catch((err) => {
        updateAuth({
          token: "",
        });
      });
  }

  checkPassword(password) {
    let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  }

  cambiaPassword() {
    window.swal
      .fire({
        title: "Cambia Password",
        html:
          '<label>Password attuale</label><input required type="password" style="margin-top: -5px" id="swal-input1" class="swal2-input">' +
          '<label style="margin-top: 5px">Nuova password</label><input required type="password" style="margin-top: -5px" id="swal-input2" class="swal2-input">' +
          '<label style="margin-top: 5px">Ripeti password</label><input required type="password" style="margin-top: -5px" id="swal-input3" class="swal2-input">',
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Salva",
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve([
              $("#swal-input1").val(),
              $("#swal-input2").val(),
              $("#swal-input3").val(),
            ]);
          });
        },
        onOpen: function () {
          $("#swal-input1").focus();
        },
        allowOutsideClick: () => !window.swal.isLoading(),
      })
      .then((result) => {
        if (result.isConfirmed) {
          let pwd_attuale = result.value[0];
          let pwd_new1 = result.value[1];
          let pwd_new2 = result.value[2];

          let isValid = this.checkPassword(pwd_new1);

          // Form is not valid.
          if (!isValid) {
            return window.swal.fire({
              title: "Requisiti password:",
              html: "- Minimo 8 caratteri<br/>- Almeno un simbolo<br/>- Almeno una lettera maiuscola",
              icon: "warning",
            });
          } else if (pwd_new1 !== pwd_new2) {
            return window.swal.fire({
              title: "Le due password non corrispondono:",
              icon: "warning",
            });
          }

          let body = {
            password_attuale: pwd_attuale,
            password1: pwd_new1,
            password2: pwd_new2,
            mode: "auth",
          };
          window.api.auth
            .cambiaPasswordAuth(body)
            .then(async (res) => {
              window.swal.fire({
                title: "Password modificata",
                icon: "success",
              });
            })
            .catch((err) => {
              showError(err);
            });
        }
      })
      .catch(swal.noop);
  }

  renderRightMenuItems(isMobile) {
    const { settings, updateSettings } = this.props;

    let authProtocol = localStorage.getItem("authProtocol");
    let cambioPasswordActive = !authProtocol || authProtocol == "passwd";
    let passwdButton = "";
    if (cambioPasswordActive) {
      passwdButton = (
        <li>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              this.cambiaPassword();
            }}
            className="nav-link"
          >
            <Icon name="key" vendor="tabler" />
          </Link>
        </li>
      );
    }

    return (
      <>
        {!isMobile ? (
          <>
            <li>Ultimo accesso: {localStorage.getItem("ultimo_accesso")}</li>
            <li>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.openInformativa();
                }}
                className="nav-link"
              >
                <Icon name="file-info" vendor="tabler" />
              </Link>
            </li>
            {passwdButton}
          </>
        ) : (
          ""
        )}
        <li>
          <Link to="#" className="nav-link" onClick={this.logOut}>
            <Icon name="logout" vendor="tabler" />
            {isMobile ? (
              <>
                <span>Esci</span>
                <span className="rui-nav-circle" />
              </>
            ) : (
              ""
            )}
          </Link>
        </li>
        {!isMobile ? (
          <li className="nav-item">
            <strong>{this.state.username}</strong>
          </li>
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    const { settings } = this.props;

    const { mobileMenuShow } = this.state;

    return (
      <>
        {/* Nav Menu */}
        <nav
          className={classnames(
            "rui-navbar rui-navbar-top",
            settings.nav_dark ? "rui-navbar-dark" : "",
            settings.nav_sticky ? "rui-navbar-sticky" : "",
            settings.nav_fixed ? "rui-navbar-fixed" : "",
            settings.nav_expand
              ? `rui-navbar-expand-${settings.nav_expand}`
              : ""
          )}
        >
          <div className="rui-navbar-brand">
            {settings.nav_logo_path ? (
              <Link to={settings.nav_logo_url} className="rui-navbar-logo">
                <img
                  src={
                    settings.night_mode || settings.nav_dark
                      ? settings.nav_logo_white_path
                      : settings.nav_logo_path
                  }
                  alt="welcome_logo"
                  width={settings.nav_logo_width}
                />
              </Link>
            ) : (
              ""
            )}

            <button
              className="yay-toggle rui-yaybar-toggle"
              type="button"
              aria-label="yaybar-toggle"
            >
              <span />
            </button>
          </div>
          <div
            className={`container${
              settings.nav_container_fluid ? "-fluid" : ""
            }`}
          >
            <div className="rui-navbar-content">
              <ul className="nav"></ul>
              <ul className="nav rui-navbar-right">
                {this.renderRightMenuItems()}
              </ul>
            </div>
          </div>
        </nav>

        {/* Mobile Menu */}
        <nav
          className={classnames(
            "rui-navbar rui-navbar-mobile",
            settings.nav_dark ? "rui-navbar-dark" : "",
            settings.nav_expand
              ? `rui-navbar-expand-${settings.nav_expand}`
              : "",
            mobileMenuShow ? "rui-navbar-show" : ""
          )}
        >
          <div className="rui-navbar-head">
            {!mobileMenuShow ? (
              <button
                className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle"
                type="button"
                aria-label="Toggle side navigation"
              >
                <span />
              </button>
            ) : (
              ""
            )}
            {settings.nav_logo_path ? (
              <Link
                to={settings.nav_logo_url}
                className="rui-navbar-logo mr-auto"
              >
                <img
                  src={
                    settings.night_mode || settings.nav_dark
                      ? settings.nav_logo_white_path
                      : settings.nav_logo_path
                  }
                  alt="welcome_logo"
                  width={settings.nav_logo_width}
                />
              </Link>
            ) : (
              ""
            )}
            {/* <div tag="a" href="#" className="dropdown-item rui-navbar-avatar">
                            <img src={settings.users[0].img} alt="" />
                        </div> */}
            <button
              className="navbar-toggler rui-navbar-toggle ml-5"
              onClick={() => {
                this.setState({
                  mobileMenuShow: !mobileMenuShow,
                });
              }}
            >
              <span />
            </button>
          </div>
          <Collapse
            isOpen={mobileMenuShow}
            className="navbar-collapse rui-navbar-collapse"
          >
            <div className="rui-navbar-content">
              <ul className="nav">
                {this.renderSubmenus(settings.navigation, true)}
                {this.renderRightMenuItems(true)}
              </ul>
            </div>
          </Collapse>
        </nav>
        <div
          className="rui-navbar-bg"
          onClick={() => {
            this.setState({
              mobileMenuShow: !mobileMenuShow,
            });
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
        />
      </>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings,
  }),
  {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings,
  }
)(PageNavbar);
