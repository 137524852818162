import socketIOClient from "socket.io-client";

export const socket = socketIOClient(window.REACT_APP_SOCKET_ENDPOINT, {
  path: window.SOCKET_PATH,
  withCredentials: true,
  transports: ["websocket", "polling"],
  reconnection: true,
});

socket.on("connect", async (data) => {
  if ("require" in window) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("req_stati");
  }
  window.swal.close();
});

socket.on("disconnect", async (data) => {
  window.swal.fire({
    title: "Connessione al server socket persa",
    text: "Tentativo di riconnessione in corso...",
    icon: "error",
    showCancelButton: false,
    showConfirmButton: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
});

/**
 * This function handle a retry mechanism for the socket connection
 * The retry parameter is used to count the number of retries
 * When the retry parameter exceeds the limit, we ask the API to force the socket connection (disconnect others and let me connect)
 * @param {URL} url 
 * @returns {String} The URL with the retry or forceSocketConnection query parameter
 */
export const getRetryUrl = (url) => {
  const connectionRetryLimit = 10;
  let retry = url.searchParams.has("retry") && !isNaN(url.searchParams.get("retry"))
        ? parseInt(url.searchParams.get("retry"))
        : 0;

  retry += 1;

  if (retry >= connectionRetryLimit) {
    url.searchParams.set("forceSocketConnection", 1);
    url.searchParams.delete("retry");
  } else {
    url.searchParams.set("retry", retry);
  }

  return url;
}