import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class Statistiche extends Component {
  componentDidMount() {
    window.api.auth
      .username()
      .then((res) => {
        window.socket.emit("monitoraggio-hello", res.data.username);
        this.setState({ username: res.data.username });
      })
      .catch((err) => {
        showError(err);
      });
  }

  componentWillUnmount() {
    if (this.state?.username) {
      window.socket.emit("monitoraggio-bye", this.state.username);
    }
  }
  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>Statistiche</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default Statistiche;
