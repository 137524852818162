/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { v4 as uuid } from "uuid";

/**
 * Internal Dependencies
 */
import Icon from "../icon";

/**
 * Component
 */
class ModalMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      tipologia: [],
      tipologie: [
        { value: "url", label: "Url" },
        { value: "static", label: "Statico" },
      ],
      media_object: [],
      searched: [],
      selectedMedia: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.dismiss();
    this.setState({
      modalOpen: false,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  async getMediaWithUrl(fileResponse, imageOrVideo) {
    const file = new Blob([fileResponse.data], {
      type: "application/octet-stream",
    });
    const fileURL = URL.createObjectURL(file);
    const url_split = fileResponse.config.url.split("=");

    return {
      id: imageOrVideo.id,
      content: url_split[url_split.length - 1],
      url: fileURL,
      name: imageOrVideo.name,
    };
  }

  async loadMedia() {
    try {
      const { mediaToHide = [] } = this.props;
      const media_object = [];

      const images = await window.api.media.immagini(this.props.tipologia);
      const multimediaImages = images.data.media;
      const filteredMedia = multimediaImages.filter(
        (item) => !mediaToHide.includes(item.id)
      );

      for (const image of filteredMedia) {
        const fileResponse = await window.api.media.file(image.name, "Immagine");
        const media = await this.getMediaWithUrl(fileResponse, image);
        media_object.push(media);
      }

      if (this.props.tipologia === "Playlist") {
        const videos = await window.api.media.video(this.props.tipologia);
        const multimediaVideos = videos.data.media;
        const filteredVideos = multimediaVideos.filter(
          (item) => !mediaToHide.includes(item.id)
        );

        for (const video of filteredVideos) {
          const fileResponse = await window.api.media.file(
            video.name,
            "Video"
          );
          const media = await this.getMediaWithUrl(fileResponse, video);
          media_object.push(media);
        }
      }

      this.setState({
        media_object: media_object,
        searched: media_object,
      });
    } catch (err) {
      console.log("err", err);
    }
  }

  salvaMedia() {
    this.props.setMedia(this.state.selectedMedia);
    this.dismiss();
  }

  handleButtonClick(m) {
    let selectedMedia = this.state.selectedMedia;

    if (this.props.title === "Media") {
      selectedMedia = [m];
    } else {
      if (selectedMedia.includes(m)) {
        selectedMedia.splice(selectedMedia.indexOf(m), 1);
      } else {
        selectedMedia.push(m);
      }
    }

    this.setState({
      selectedMedia: selectedMedia,
    });
  }

  change(e) {
    let filter = e.target.value.toLowerCase();
    let searched = [];
    if (filter === "") {
      searched = this.state.media_object;
    } else {
      this.state.media_object.map((media) => {
        if (media.name.toLowerCase().includes(filter)) {
          searched.push(media);
        }
      });
    }
    this.setState({
      searched: searched,
    });
  }

  async componentDidMount() {
    await this.loadMedia();
  }

  render() {
    const { selectedMedia, searched } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="lg"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title h2">{this.props.title}</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <Col>
            <Input
              type="text"
              placeholder="Cerca..."
              onChange={(e) => this.change(e)}
            />
          </Col>
          <ModalBody>
            <Row>
              {searched.map((m) => {
                return (
                  <Col lg="3">
                    {selectedMedia.indexOf(m) > -1 ? (
                      <span className="check-badge">✔</span>
                    ) : (
                      <></>
                    )}
                    <Button
                      size="lg"
                      onClick={() => this.handleButtonClick(m)}
                      className={
                        selectedMedia.indexOf(m) > -1 ? "btn-media" : ""
                      }
                      style={{
                        width: "100%",
                        height: "100px",
                        backgroundImage: "url('" + m.url + "')",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginBottom: "25px",
                      }}
                    ></Button>
                    <span>{m.name}</span>
                  </Col>
                );
              })}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="form" color="brand" onClick={() => this.salvaMedia()}>
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(ModalMedia);
