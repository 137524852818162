/**
 * External Dependencies
 */
import Cookies from "js-cookie";
import Omit from "object.omit";
import { combineReducers } from "redux";

/**
 * Internal Dependencies
 */
import {
  ADD_TOAST,
  REMOVE_TOAST,
  SIGNIN,
  UPDATE_AUTH,
  UPDATE_SETTINGS,
} from "../actions";
import defaultSettings from "../settings";
import { getUID } from "../utils";

// initial state.
const INITIAL_SETTINGS_STATE = {
  ...defaultSettings,
};
const INITIAL_AUTH_STATE = {
  token: Cookies.get("welcome-auth-token"),
};
const INITIAL_USER_STATE = null;
const INITIAL_TOASTS_STATE = [];

/**
 * Reducer
 */
const rootReducer = combineReducers({
  auth: (state = INITIAL_AUTH_STATE, action) => {
    switch (action.type) {
      case UPDATE_AUTH:
        if (typeof action.auth.token !== "undefined") {
          if (action.auth.token == "") {
            let pageCookie = Cookies.get();
            Object.keys(pageCookie).forEach((k) => {
              Cookies.remove(k);
            });
            localStorage.removeItem("authProtocol");
          } else {
            // save token to cookies for 28 days.
            Cookies.set("welcome-auth-token", action.auth.token, {
              expires: 28,
              sameSite: "strict",
            });
          }
        }

        return Object.assign({}, state, action.auth);
      default:
        return state;
    }
  },
  user: (state = INITIAL_USER_STATE, action) => {
    switch (action.type) {
      case SIGNIN:
        if (!action.user) {
          return state;
        }
        Cookies.set("user", JSON.stringify(action.user));
        return Object.assign({}, state, action.user);
      default:
        return state;
    }
  },
  settings: (state = INITIAL_SETTINGS_STATE, action) => {
    switch (action.type) {
      case UPDATE_SETTINGS:
        return Object.assign({}, state, action.settings);
      default:
        return state;
    }
  },
  toasts: (state = INITIAL_TOASTS_STATE, action) => {
    switch (action.type) {
      case ADD_TOAST:
        const newData = {
          ...{
            title: "",
            content: "",
            color: "brand",
            time: false,
            duration: 0,
            closeButton: true,
          },
          ...action.data,
        };

        if (newData.time === true) {
          newData.time = new Date();
        }

        return {
          ...state,
          [getUID()]: newData,
        };
      case REMOVE_TOAST:
        if (!action.id || !state[action.id]) {
          return state;
        }
        return Omit(state, action.id);
      default:
        return state;
    }
  },
});

export default rootReducer;
