/**
 * Styles.
 */
import "./body.scss";
import "./header.css";
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { showSocketConnectionRetryAlertMessage } from "../../../utils/alert";
import { getRetryUrl } from "../../../utils/socket-io";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../components/async-component";

/**
 * Component
 */
class Display_Live extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      autorizzato: false,
    };
  }

  checkDisplayConnesso(id) {
    const currentUrl = new URL(window.location);
    const forceSocketConnection = currentUrl.searchParams.get(
      "forceSocketConnection"
    );
    window.api.display
      .checkConnesso(id, forceSocketConnection)
      .then((res) => {
        const connessioneNonDisponibile = res.data.connesso;
        if (connessioneNonDisponibile) {
          showSocketConnectionRetryAlertMessage("Display già in uso", 10)
          .then(() => {
            const retryUrl = getRetryUrl(currentUrl);
            window.location.assign(retryUrl);
          });
        } else {
          if (currentUrl.searchParams.has("forceSocketConnection")) {
            currentUrl.searchParams.delete("forceSocketConnection");
            window.location.assign(currentUrl);
          }

          if (window.socket.connected) {
            window.socket.emit("display-hello", id);
          }
          window.socket.on("connect", async (data) => {
            window.socket.emit("display-hello", id);
          });
          window.socket.on("riavvio", async (data) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const { history } = this.props;

    let id = this.props.match.params.id;

    if (id != undefined) {
      this.setState(
        {
          autorizzato: true,
          loading: false,
        },
        () => this.checkDisplayConnesso(id)
      );
    } else {
      history.push("/");
    }
  }

  render() {
    const { autorizzato, loading } = this.state;

    return (
      <div>
        {!loading ? (
          autorizzato ? (
            <AsyncComponent component={() => import("./content")} />
          ) : (
            <div className="rui-sign align-items-center justify-content-center">
              <p className="display-4 mb-30">Credenziali Display Errate</p>
            </div>
          )
        ) : (
          <div className="rui-sign align-items-center justify-content-center">
            <p className="display-4 mb-30">Caricamento...</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Display_Live);
