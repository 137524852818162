/**
 * External Dependencies
 */
import parse from "html-react-parser";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col, CustomInput, Form, Row } from "reactstrap";
/**
 * Component
 */
class InformativaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titolo: "",
      testo: "",
      checkboxInformativa: false,
      informativaAccettata: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    if (this.state.checkboxInformativa) {
      window.api.auth
        .enablePolicy()
        .then((res) => {
          this.setState({ informativaAccettata: true });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.swal.fire({
        icon: "warning",
        text: "E' necessario accettare l'informativa per continuare.",
      });
    }
  };

  getInformativa() {
    window.api.auth
      .getPolicy()
      .then((res) => {
        let { title, body } = res.data.informativa;
        this.setState({ titolo: title, testo: body });

        if (!res.data.policy) {
        } else {
          this.setState({ informativaAccettata: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getInformativa();
  }

  componentDidUpdate() {
    if (this.state.informativaAccettata) {
      if (this.props.embedded == true) {
        this.props.onClose();
      } else {
        this.props.history.goBack();
      }
    }
  }

  render() {
    return (
      <Fragment>
        <Form id="form" onSubmit={this.submitForm}>
          <h3 className="titolo-informativa">{this.state.titolo}</h3>
          <div className="testo-informativa">{parse(this.state.testo)}</div>
          <Row>
            <Col>
              <Row>
                <CustomInput
                  checked={this.state.checkboxInformativa}
                  label="Ho letto e accetto l'informativa"
                  type="checkbox"
                  className="fancy-checkbox-left"
                  name="checkboxInformativa"
                  id="checkboxInformativa"
                  onClick={(e) =>
                    this.setState({ checkboxInformativa: e.target.checked })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Button form="form" color="brand">
            Continua
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(InformativaContent);
