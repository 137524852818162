/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class AmbulatorialeLight extends Component {
  render() {
    const { settings } = this.props;

    return (
      <PageWrap>
        <PageTitle>
          <h3 id="page-title" style={{ fontSize: "3rem" }}>
            {settings.nome_sportello_light}
          </h3>
        </PageTitle>
        <PageContent style={{ marginTop: "-45px" }}>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(AmbulatorialeLight);
