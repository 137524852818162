/**
 * External Dependencies
 */
import { IconFlagOff } from "@tabler/icons";
import React from "react";
import { connect } from "react-redux";

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const Bandiera = (props) => {
  const { settings, country, size } = props;
  let esiste = settings.img_country[country];
  if (esiste) {
    return (
      <img
        style={{
          width: size ? size : "15px",
          border: "1px solid",
          borderRadius: "3px",
        }}
        {...props}
        src={settings.img_country[country]}
        alt={country}
      />
    );
  } else {
    return <IconFlagOff size={size ? size : "15"} />;
  }
};

export default connect(({ settings }) => ({
  settings,
}))(Bandiera);
