/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class Permessi extends Component {
  render() {
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>Permessi</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default Permessi;
