import "./style.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

import PageContent from "../../../components/page-content";
import PageTitle from "../../../components/page-title";
import PageWrap from "../../../components/page-wrap";
import FormLayout from "./components/form-layout";
import TabellaLayout from "./components/tabella-layout";

class TotemLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form_status: false,
      type: undefined,
      id: undefined,
    };
    this.changeFormStatus = this.changeFormStatus.bind(this);
  }

  changeFormStatus(type = undefined, stato = undefined, id = undefined) {
    if (stato != undefined) {
      this.setState({
        form_status: stato,
        type: type,
        id: id,
      });
    } else {
      this.setState({
        form_status: !this.state.form_status,
        type: type,
        id: id,
      });
    }
  }

  render() {
    const { form_status, type, id } = this.state;

    const { sidenav } = this.props;

    let button = <></>;
    let content = <></>;

    if (form_status) {
      button = (
        <Button
          className="welcome-custom-btn"
          onClick={() => this.changeFormStatus(1)}
          color="brand"
        >
          Indietro
        </Button>
      );
      content = (
        <FormLayout status={this.changeFormStatus} type={type} id={id} />
      );
    } else {
      button = (
        <>
          <Button
            className="welcome-custom-btn"
            onClick={() => this.changeFormStatus(1)}
            color="brand"
          >
            Nuovo Layout
          </Button>
        </>
      );
      content = <TabellaLayout status={this.changeFormStatus} />;
    }

    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>Totem Layout</h1>
        </PageTitle>
        <PageContent>
          {button}
          <br />
          <br />
          {content}
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(
  ({ sidenav }) => ({
    sidenav,
  }),
  {}
)(withRouter(TotemLayout));
