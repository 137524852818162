/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class Monitoraggio extends Component {
  componentDidMount() {
    window.api.auth
      .username()
      .then((res) => {
        window.socket.emit("monitoraggio-hello", res.data.username);
        this.setState({ username: res.data.username });
      })
      .catch((err) => {
        showError(err);
      });
  }

  componentWillUnmount() {
    if (this.state?.username) {
      window.socket.emit("monitoraggio-bye", this.state.username);
    }
  }

  render() {
    const { settings } = this.props;

    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>{settings.nome_ambiente}</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(Monitoraggio);
