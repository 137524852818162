/**
 * @typedef Playlist
 * @property {UUID} id
 * @property {string} name
 * @property {Array<Object>} images
 */
/**
 * @param {UUID} idAmbiente
 * @returns {Promise<Array<Playlist>>}
 */
export const getPlaylists = async () => {
  return window.api.playlist.list().then((response) => {
    return response.data;
  });
};

/**
 * @typedef Playlist
 * @property {UUID} id
 * @property {string} name
 * @property {Array<Object>} images
 */
/**
 * @param {UUID} idAmbiente
 * @returns {Promise<Array<Playlist>>}
 */
export const getPlaylistsFromAmbiente = async (idAmbiente) => {
  const listResponse = await window.api.playlist.list({ idAmbiente });

  return listResponse.data;
};
/**
 * @param {Number} id
 * @returns {Promise<Playlist>}
 */
export const getPlaylistById = async (id) => {
  const getResponse = await window.api.playlist.get(id);
  return getResponse.data;
};

/**
 * @param {Playlist} playlist
 * @returns {Promise<Playlist>}
 */
export const createPlaylist = async (playlist) => {
  const createResponse = await window.api.playlist.create(playlist);
  return createResponse.data;
};

/**
 *
 * @param {Number} id
 * @param {Playlist} playlist
 * @returns {Promise<Playlist>}
 */
export const updatePlaylist = async (id, playlist) => {
  const updateResponse = await window.api.playlist.update(id, playlist);
  return updateResponse.data;
};

/**
 * @param {Number} id
 * @returns {Promise<string>}
 */
export const deletePlaylist = async (id) => {
  const deleteResponse = await window.api.playlist.delete(id);
  return deleteResponse.data;
};
