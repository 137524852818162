/**
 * External Dependencies
 */
import React, { Component } from "react";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../components/async-component";
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";

/**
 * Component
 */
class AmbulatorialeCode extends Component {
  render() {
    return (
      <PageWrap>
        <PageTitle>
          <h1 id="page-title" style={{ fontSize: "3rem" }}>
            Sportello Ambulatoriale Code
          </h1>
        </PageTitle>
        <PageContent style={{ marginTop: "-20px" }}>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default AmbulatorialeCode;
