/**
 * Styles
 */
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "./style.scss";

/**
 * Internal Dependencies
 */
import { applyMiddleware, createStore } from "redux";
import Swal from "sweetalert2";
import reducers from "./reducers";
import { internal_api } from "./utils/axios";
import { socket } from "./utils/socket-io";

/**
 * External Dependencies
 */
const jQuery = require("jquery");

window.jQuery = jQuery;
window.$ = jQuery;

const createStoreWithMiddleware = applyMiddleware()(createStore);
window.api = internal_api;
window.store = createStoreWithMiddleware(reducers);
window.socket = socket;
window.swal = Swal;

/*------------------------------------------------------------------

  RootUI Class

-------------------------------------------------------------------*/
class ROOTUI {}
window.RootUI = new ROOTUI();
