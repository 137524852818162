/**
 * External Dependencies
 */
import React from "react";
import Loadable from "react-loadable";
import { Spinner } from "reactstrap";

export default function AsyncComponent(props) {
  const LoadableComponent = Loadable({
    loader: props.component,
    loading: () => {
      return (
        <Spinner color="secondary" type="grow">
          Caricamento...
        </Spinner>
      );
    },
  });

  return <LoadableComponent />;
}
