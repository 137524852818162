/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";

/**
 * Internal Dependencies
 */
import Bandiera from "../../../../components/flags";
import Icon from "../../../../components/icon";
import { showError } from "../../../../utils";

/**
 * Component
 */
class FormLingue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      loading: true,
    };

    this.toggle = this.toggle.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  toggle() {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Le modifiche effettuate non verranno salvate",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.dismiss();
        this.setState({
          modalOpen: false,
        });
      }
    });
  }

  submitForm = async (e) => {
    e.preventDefault();
    const data = {};
    data.id = this.props.selectedLayoutMonitor;
    let lingueTradotte = {};
    for (let lang of this.state.lingue) {
      lingueTradotte[lang.id] = {
        sez1_col1: lang.sez1_col1,
        sez1_col2: lang.sez1_col2,
        sez2_col1: lang.sez2_col1,
        sez2_col2: lang.sez2_col2,
        sez3_col1: lang.sez3_col1,
        sez3_col2: lang.sez3_col2,
        sez4_col1: lang.sez4_col1,
        sez4_col2: lang.sez4_col2,
      };
    }

    data.lingue = lingueTradotte;

    console.log(data);
    window.api.monitor.layout
      .traduci(data)
      .then((res) => {
        this.dismiss();
        window.swal.fire({
          title: "Traduzioni effettuate",
          icon: "success",
        });
      })
      .catch((err) => {
        showError(err);
      });
  };

  dismiss() {
    this.props.unmountMe();
  }

  caricaLingue() {
    window.api.locale
      .elenco()
      .then((res) => {
        let lingue = res.data.lingue;
        window.api.monitor.layout
          .traduzioni(this.props.selectedLayoutMonitor)
          .then(async (res) => {
            let tradotte = res.data.traduzione;
            let lingueDisponibili = [];
            for (let lingua of lingue) {
              if (lingua.id != "it") {
                lingueDisponibili.push({
                  id: lingua.id,
                  name: lingua.name,
                  sez1_col1: tradotte[lingua.id]?.sez1_col1,
                  sez1_col2: tradotte[lingua.id]?.sez1_col2,
                  sez2_col1: tradotte[lingua.id]?.sez2_col1,
                  sez2_col2: tradotte[lingua.id]?.sez2_col2,
                  sez3_col1: tradotte[lingua.id]?.sez3_col1,
                  sez3_col2: tradotte[lingua.id]?.sez3_col2,
                  sez4_col1: tradotte[lingua.id]?.sez4_col1,
                  sez4_col2: tradotte[lingua.id]?.sez4_col2,
                });
              }
            }
            this.setState({
              lingue: lingueDisponibili,
              infoMonitorLayout: res.data.infoMonitorLayout,
              loading: false,
            });
          })
          .catch((err) => {
            showError(err);
          });
      })
      .catch((err) => {
        showError(err);
      });
  }

  componentDidMount() {
    this.caricaLingue();
  }

  render() {
    const { lingue, infoMonitorLayout } = this.state;

    let gestisciLingue = [];
    if (!this.state.loading) {
      for (let lingua of lingue) {
        gestisciLingue.push(
          <>
            <hr />
            <Row>
              <Col>
                <Bandiera country={lingua.id} size={"2em"} />
                &nbsp;{lingua.name} [{lingua.id}]
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez1_col1">
                    Sezione 1 [default: {infoMonitorLayout?.sez1_col1}]
                  </Label>
                  <Input
                    value={lingua.sez1_col1}
                    type="text"
                    name="sez1_col1"
                    id={`sez1_col1_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez1_col1_${lan.id}` == e.target.id) {
                          lan.sez1_col1 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez1_col2">
                    [default: {infoMonitorLayout?.sez1_col2}]
                  </Label>
                  <Input
                    value={lingua.sez1_col2}
                    type="text"
                    name="sez1_col2"
                    id={`sez1_col2_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez1_col2_${lan.id}` == e.target.id) {
                          lan.sez1_col2 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez2_col1">
                    Sezione 2 [default: {infoMonitorLayout?.sez2_col1}]
                  </Label>
                  <Input
                    value={lingua.sez2_col1}
                    type="text"
                    name="sez2_col1"
                    id={`sez2_col1_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez2_col1_${lan.id}` == e.target.id) {
                          lan.sez2_col1 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez2_col2">
                    [default: {infoMonitorLayout?.sez2_col2}]
                  </Label>
                  <Input
                    value={lingua.sez2_col2}
                    type="text"
                    name="sez2_col2"
                    id={`sez2_col2_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez2_col2_${lan.id}` == e.target.id) {
                          lan.sez2_col2 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez3_col1">
                    Sezione 3 [default: {infoMonitorLayout?.sez3_col1}]
                  </Label>
                  <Input
                    value={lingua.sez3_col1}
                    type="text"
                    name="sez3_col1"
                    id={`sez3_col1_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez3_col1_${lan.id}` == e.target.id) {
                          lan.sez3_col1 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez3_col2">
                    [default: {infoMonitorLayout?.sez3_col2}]
                  </Label>
                  <Input
                    value={lingua.sez3_col2}
                    type="text"
                    name="sez3_col2"
                    id={`sez3_col2_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez3_col2_${lan.id}` == e.target.id) {
                          lan.sez3_col2 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez4_col1">
                    Sezione 4 [default: {infoMonitorLayout?.sez4_col1}]
                  </Label>
                  <Input
                    value={lingua.sez4_col1}
                    type="text"
                    name="sez4_col1"
                    id={`sez4_col1_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez4_col1_${lan.id}` == e.target.id) {
                          lan.sez4_col1 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ marginTop: "15px" }}>
                  <Label for="sez4_col2">
                    [default: {infoMonitorLayout?.sez4_col2}]
                  </Label>
                  <Input
                    value={lingua.sez4_col2}
                    type="text"
                    name="sez4_col2"
                    id={`sez4_col2_${lingua.id}`}
                    placeholder="testo"
                    onChange={(e) => {
                      let tradotte = this.state.lingue;
                      for (let lan of tradotte) {
                        if (`sez4_col2_${lan.id}` == e.target.id) {
                          lan.sez4_col2 = e.target.value;
                        }
                      }
                      this.setState({ lingue: tradotte });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      }
    }

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title h2">
              {infoMonitorLayout?.nome ? `${infoMonitorLayout.nome}` : "Layout"}
            </h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Form id="form" onSubmit={this.submitForm}>
              <Row>
                <Col>{gestisciLingue}</Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="form" color="brand">
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default FormLingue;
