/**
 * External Dependencies
 */
import Cookies from "js-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

/**
 * Internal Dependencies
 */
import { updateAuth as actionUpdateAuth } from "./actions";
import ErrorBoundary from "./ErrorBoundary";
import RoutesList from "./pages";
import NotFoundPage from "./pages/404";

/**
 * Component
 */
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.checkLoggedIn();
    this.checkPolicy();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      this.checkLoggedIn();
      this.checkPolicy();
    }
  }

  checkPolicy() {
    const { updateAuth } = this.props;

    if (
      ["/403", "/sign-in", "/404", "/sso-token"].indexOf(
        this.props.location.pathname
      ) > -1 ||
      [
        "success",
        "error",
        "monitor-l",
        "totem-l",
        "display-l",
        "ambulatoriale-light",
        "ambulatoriale-code",
        "ambulatoriale-code-full",
        "reset-password",
        "informativa",
      ].indexOf(window.location.pathname.split("/")[1]) > -1
    ) {
      return;
    }

    if (Cookies.get("policy") != "accepted") {
      window.api.auth
        .getPolicy()
        .then((res) => {
          if (!res.data.policy) {
            this.props.history.push("/informativa");
          } else {
            let inFourHours = new Date(new Date().getTime() + 240 * 60 * 1000);
            Cookies.set("policy", "accepted", {
              expires: inFourHours,
            });
          }
        })
        .catch((err) => {
          updateAuth({
            token: "",
          });
        });
    }
  }

  checkPermission() {
    const { history } = this.props;
    window.api.risorse
      .pathAuth(window.location.pathname)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (!res.data.autorizzato) {
          history.push("/403");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkLoggedIn() {
    const { updateAuth } = this.props;

    window.swal.close();

    this.setState({
      loading: true,
    });

    if (
      ["/403", "/sign-in", "/404", "/sso-token"].indexOf(
        this.props.location.pathname
      ) > -1 ||
      [
        "success",
        "error",
        "monitor-l",
        "totem-l",
        "display-l",
        "ambulatoriale-light",
        "ambulatoriale-code",
        "ambulatoriale-code-full",
        "reset-password",
      ].indexOf(window.location.pathname.split("/")[1]) > -1
    ) {
      this.setState({
        loading: false,
      });
    } else {
      window.api.auth
        .loggedIn()
        .then((res) => {
          if (!res.data.loggedIn) {
            updateAuth({
              token: "",
            });
          } else {
            updateAuth({
              token: "true",
            });
            this.checkPermission();
          }
        })
        .catch((err) => {
          updateAuth({
            token: "",
          });
        });
    }
  }

  render() {
    const { location } = this.props;

    const { loading } = this.state;

    return (
      <ErrorBoundary>
        <Switch location={location}>
          {Object.keys(RoutesList).map((path) => {
            const RouteInner = RoutesList[path];

            return (
              <Route
                key={path}
                path={path}
                exact
                render={() => (!loading ? <RouteInner /> : <></>)}
              />
            );
          })}

          {/* 404 */}
          <Route render={() => <NotFoundPage />} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default connect(
  ({ auth }) => ({
    auth,
  }),
  { updateAuth: actionUpdateAuth }
)(withRouter(Routes));
