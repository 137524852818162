/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { v4 as uuid } from "uuid";

/**
 * Internal Dependencies
 */
import Icon from "../../../../components/icon";
/* import Carousel from './carousel'; */

/**
 * Component
 */
class ModalMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      tipologia: [],
      media_object: [],
      selectedMedia: [],
      searched: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.dismiss();
    this.setState({
      modalOpen: false,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  submitForm = (e) => {
    e.preventDefault();

    let settings = {
      tipologia: this.state.tipologia,
      url: this.state.url,
      static_text: this.state.static_text,
    };

    this.props.setTickerSettings(settings);
    this.dismiss();
  };

  loadMedia() {
    window.api.media
      .immagini(this.props.tipologia)
      .then((res) => {
        let multimedia = res.data.media;

        let media_object = [];
        multimedia.forEach(async (media) => {
          window.api.media
            .file(media.name, "Immagine")
            .then((response) => {
              const file = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const fileURL = URL.createObjectURL(file);

              let url_split = response.config.url.split("=");

              media_object.push({
                id: uuid(),
                content: url_split[url_split.length - 1],
                url: fileURL,
                name: media.name,
              });

              this.setState({
                media_object: media_object,
                searched: media_object,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  salvaMedia() {
    this.props.setMedia(this.state.selectedMedia);
    this.dismiss();
  }

  handleButtonClick(m) {
    let selectedMedia = this.state.selectedMedia;

    if (this.props.title === "Media") {
      selectedMedia = [m];
    } else {
      if (selectedMedia.includes(m)) {
        selectedMedia.splice(selectedMedia.indexOf(m), 1);
      } else {
        selectedMedia.push(m);
      }
    }

    this.setState(
      {
        selectedMedia: selectedMedia,
      },
      () => console.log(this.state.selectedMedia)
    );
  }

  change(e) {
    let filter = e.target.value.toLowerCase();
    let searched = [];
    if (filter === "") {
      searched = this.state.media_object;
    } else {
      this.state.media_object.map((media) => {
        if (media.name.toLowerCase().includes(filter)) {
          searched.push(media);
        }
      });
    }
    this.setState({
      searched: searched,
    });
  }

  componentDidMount() {
    this.loadMedia();
  }

  render() {
    const { searched, selectedMedia } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="lg"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title h2">{this.props.title}</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Row>
              {searched.map((m) => {
                return (
                  <Col lg="3">
                    {selectedMedia.indexOf(m) > -1 ? (
                      <span className="check-badge">✔</span>
                    ) : (
                      <></>
                    )}
                    <Button
                      size="lg"
                      onClick={() => this.handleButtonClick(m)}
                      className={
                        selectedMedia.indexOf(m) > -1 ? "btn-media" : ""
                      }
                      style={{
                        width: "100%",
                        height: "100px",
                        backgroundImage: "url('" + m.url + "')",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginBottom: "25px",
                      }}
                    ></Button>
                    <span>{m.name}</span>
                  </Col>
                );
              })}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="form" color="brand" onClick={() => this.salvaMedia()}>
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(ModalMedia);
