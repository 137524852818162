/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

/**
 * Internal Dependencies
 */
import Icon from "../icon";
/* import Carousel from './carousel'; */

/**
 * Component
 */
class ModalButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      bottoni: [],
      selectedButtons: [],
      searched: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.dismiss();
    this.setState({
      modalOpen: false,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  async risolviMedia(nome_file) {
    return new Promise((resolve, reject) => {
      window.api.media
        .file(nome_file, "Immagine")
        .then((res) => {
          const file = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const fileURL = URL.createObjectURL(file);

          resolve(fileURL);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  loadBottoni() {
    window.api.totem.bottoni
      .elenco()
      .then(async (res) => {
        let bottoni = res.data.bottoni;

        for (let bottone of bottoni) {
          let sfondo;
          if (bottone.sfondo !== null) {
            sfondo = await this.risolviMedia(bottone.sfondo);
          }
          bottone.url = sfondo;
        }

        let selectedButtons = [];
        if (this.props.buttons) {
          for (let b of this.props.buttons) {
            selectedButtons.push(b.id);
          }
        }

        this.setState({
          selectedButtons: selectedButtons,
          bottoni: bottoni,
          searched: bottoni,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  change(e) {
    let filter = e.target.value.toLowerCase();
    let searched = [];
    if (filter === "") {
      searched = this.state.bottoni;
    } else {
      this.state.bottoni.map((bottone) => {
        if (bottone.content.toLowerCase().includes(filter)) {
          searched.push(bottone);
        }
      });
    }
    this.setState({
      searched: searched,
    });
  }

  salvaBottoni() {
    let buttons = [];
    this.state.selectedButtons.map((b) => {
      let bot = this.state.bottoni.find((o) => o.id === b);
      if (bot.tipo == "pagina") {
        let botP = this.props.buttons?.find((o) => o.id == b);
        if (botP) {
          bot.pagina = botP.pagina;
        }
      }
      buttons.push(bot);
    });
    this.props.setButtons(buttons);
    this.dismiss();
  }

  componentDidMount() {
    this.loadBottoni();
  }

  handleButtonClick(button) {
    let selectedButtons = this.state.selectedButtons;
    if (selectedButtons.includes(button.id)) {
      selectedButtons.splice(selectedButtons.indexOf(button.id), 1);
    } else {
      selectedButtons.push(button.id);
    }

    this.setState({
      selectedButtons: selectedButtons,
    });
  }

  render() {
    const { searched, selectedButtons } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="lg"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title h2">Bottoni</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <Col>
            <Input
              type="text"
              placeholder="Cerca..."
              onChange={(e) => this.change(e)}
            />
          </Col>
          <ModalBody>
            <Row>
              {searched.map((b) => {
                return (
                  <Col lg="3">
                    {selectedButtons.indexOf(b.id) > -1 ? (
                      <span className="check-badge">✔</span>
                    ) : (
                      <></>
                    )}
                    <Button
                      size="lg"
                      color={b.tipo == "pagina" ? "success" : "brand"}
                      onClick={() => this.handleButtonClick(b)}
                      className={
                        selectedButtons.indexOf(b.id) > -1 ? "btn-media" : ""
                      }
                      style={{
                        width: "100%",
                        height: "auto",
                        marginBottom: "25px",
                        display: "table-cell",
                        verticalAlign: "middle",
                        position: "relative",
                      }}
                    >
                      {b.content}
                      {
                        <span
                          style={{
                            fontSize: "0.55em",
                            float: "right",
                            verticalAlign: "middle",
                            position: "absolute",
                            bottom: "2px",
                            right: "5px",
                          }}
                        >
                          {b.tipo == "informativo"
                            ? "INFO"
                            : b.tipo == "coda con ritardo"
                            ? "CODA RIT"
                            : b.tipo}
                        </span>
                      }
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button
              form="form"
              color="brand"
              onClick={() => this.salvaBottoni()}
            >
              Salva
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(ModalButtons);
