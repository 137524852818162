/**
 * Styles.
 */
import "./style.scss";

/**
 * External Dependencies
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../components/async-component";

/**
 * Component
 */
class Totem_Live extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      autorizzato: false,
    };
  }

  checkTotemConnesso(id) {
    window.api.totem
      .checkConnesso(id)
      .then((res) => {
        let connesso = res.data.connesso;
        if (connesso) {
          this.setState({
            loading: true,
          });
          window.swal.fire({
            title: "Totem già in uso.",
            text: "nuovo tentativo di connessione...",
            icon: "warning",
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        } else {
          if (window.socket.connected) {
            window.socket.emit("totem-hello", id);
          }
          window.socket.on("connect", async (data) => {
            window.socket.emit("totem-hello", id);
          });
          window.socket.on("riavvio", async (data) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const { history } = this.props;

    let id = this.props.match.params.id;

    if (id != undefined) {
      this.setState(
        {
          autorizzato: true,
          loading: false,
        },
        () => this.checkTotemConnesso(id)
      );
    } else {
      history.push("/");
    }
  }

  render() {
    const { autorizzato, loading } = this.state;

    return (
      <div className="rui-sign align-items-center justify-content-center">
        {!loading ? (
          autorizzato ? (
            <AsyncComponent component={() => import("./content")} />
          ) : (
            <div className="rui-sign align-items-center justify-content-center">
              <p className="display-4 mb-30">Credenziali Totem Errate</p>
            </div>
          )
        ) : (
          <div className="rui-sign align-items-center justify-content-center">
            <p className="display-4 mb-30">Caricamento...</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Totem_Live);
