import { IconGripVertical } from "@tabler/icons";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Input, Row, Table } from "reactstrap";

function SortableButtons(props) {
  const { rows, onRowsUpdate, pagine } = props;

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedRows = [...rows];
    const [removedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, removedRow);
    onRowsUpdate(updatedRows);
  };

  const handlePageSelection = (id, value) => {
    const updatedRows = [...rows];
    for (let riga of updatedRows) {
      if (riga.id == id) {
        riga.pagina = value;
      }
    }
    onRowsUpdate(updatedRows);
  };

  const select = (rowId, selected) => {
    let opt = [];
    opt.push(
      <option key={-1} value={-1} selected={!selected || -1 === selected}>
        -
      </option>
    );
    if (pagine) {
      for (let [i, p] of Object.entries(pagine)) {
        opt.push(
          <option key={i} value={p.index} selected={p.index === selected}>
            {p.name ? p.name : "Pag. " + p.index}
          </option>
        );
      }
    }
    return (
      <Input
        type="select"
        style={{ padding: 0 }}
        onChange={(e) => handlePageSelection(rowId, e.target.value)}
      >
        {opt}
      </Input>
    );
  };

  const renderRow = (row, index) => (
    <Draggable key={row.id} draggableId={row.id} index={index}>
      {(provided) => (
        <tr
          key={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <td style={{ width: "20px", border: 0 }}>
            <IconGripVertical />
          </td>
          <td
            style={{
              width: "300px",
              border: "1px solid rgb(255, 255, 255)",
              color: "#FFF",
              backgroundColor: "#0288d1",
              margin: "2px",
              borderRadius: "10px",
            }}
          >
            <Row>
              {" "}
              <Col>{row.content}</Col>
              <Col>
                {row.tipo == "pagina" ? select(row.id, row.pagina) : <></>}
              </Col>
            </Row>
          </td>
        </tr>
      )}
    </Draggable>
  );

  return (
    <div>
      <Table style={{ width: "100%" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table">
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {rows.map(renderRow)}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </div>
  );
}

export default SortableButtons;
