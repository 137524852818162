import React, { Component } from "react";
import { Button, Col } from "reactstrap";
import Icon from "../icon";

import ModalButtons from "./modal-buttons";
import SortableButtons from "./SortableButtons";

class ColumnButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elencoBottoni: [],
    };
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.handleRowsUpdate = this.handleRowsUpdate.bind(this);
  }

  handleChildUnmount() {
    this.setState({
      renderChildButtons: false,
    });
  }

  componentDidMount() {
    this.setState({
      elencoBottoni: this.props.elencoBottoni,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        elencoBottoni: this.props.elencoBottoni,
      });
    }
  }

  handleRowsUpdate(newRows) {
    let iterator = newRows.keys();
    for (const key of iterator) {
      newRows[key].posizione = key;
    }
    this.props.setButtons(newRows, this.props.indice);
  }

  render() {
    const { elencoBottoni } = this.state;

    return (
      <>
        {this.state.renderChildButtons ? (
          <ModalButtons
            unmountMe={this.handleChildUnmount}
            setButtons={this.handleRowsUpdate}
            tipologia={this.props.tipologia}
            buttons={elencoBottoni}
            indice={this.props.indice}
          />
        ) : null}
        <Col
          className="btn-item"
          style={{
            borderRight: "1px solid black",
            marginTop: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="brand"
            className="btn-uniform btn-item btn-round"
            onClick={() => this.setState({ renderChildButtons: true })}
            style={{
              marginTop: "5px",
              width: "auto",
              height: "auto",
              color: "white",
              border: "1px solid black",
            }}
          >
            <Icon name="edit" vendor="tabler" />
          </Button>
          <SortableButtons
            pagine={this.props.pagine}
            rows={elencoBottoni}
            onRowsUpdate={this.handleRowsUpdate}
          />
        </Col>
      </>
    );
  }
}
export default ColumnButton;
