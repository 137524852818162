/**
 * Styles
 */
import "./style.scss";

/**
 * External Dependencies
 */
import classnames from "classnames/dedupe";
import React, { Component } from "react";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import { Toast, ToastBody } from "reactstrap";

/**
 * Internal Dependencies
 */
import {
  addToast as actionAddToast,
  removeToast as actionRemoveToast,
} from "../../actions";

import Icon from "../icon";

/**
 * Component
 */
class PageToasts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenToasts: {},
    };
    this.timerToasts = {};

    this.maybePrepareToastsTimeout = this.maybePrepareToastsTimeout.bind(this);
    this.hideToast = this.hideToast.bind(this);
  }

  componentDidMount() {
    const { auth, addToast } = this.props;

    window.socket.on("messaggio_errore", async (data) => {
      if (
        ["web"].indexOf(window.location.pathname.split("/")[1]) > -1 &&
        data != undefined &&
        data.length > 0
      ) {
        for (let msg of data) {
          if (msg != "ok") {
            addToast({
              title: "Errori Dispositivi",
              content: msg,
              duration: msg !== "Carta finita" ? 10000 : undefined,
              time: new Date(),
            });
          }
        }
      }
    });

    window.socket.on("messaggio_admin", async (data) => {
      if (
        ["web"].indexOf(window.location.pathname.split("/")[1]) > -1 &&
        data != undefined &&
        data.length > 0
      ) {
        addToast({
          title: "Messaggio Admin",
          content: data,
          time: new Date(),
        });
      }
    });

    window.socket.on("aggiorna_stati", async (data) => {
      if (
        [
          "web",
          "ambulatoriale-light",
          "ambulatoriale-code",
          "ambulatoriale-code-full",
        ].indexOf(window.location.pathname.split("/")[1]) > -1 &&
        data?.ticket
      ) {
        addToast({
          title: "Nuovo Ticket",
          content: data.ticket,
          // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
          time: new Date(),
          duration: 5000,
        });
      }
    });

    // Startup toast when already logged in
    if (
      auth.token &&
      [
        "totem-l",
        "monitor-l",
        "sportello-l",
        "display-l",
        "ambulatoriale-light",
        "ambulatoriale-code",
        "ambulatoriale-code-full",
      ].indexOf(window.location.pathname.split("/")[1]) == -1
    ) {
      setTimeout(() => {
        addToast({
          title: "Benvenuto su Welcome!",
          content: (
            <>
              Benvenuto su <strong>Welcome</strong> gestore eliminacode. Ti
              auguriamo una buona giornata 😃
            </>
          ),
          // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
          time: new Date(),
          duration: 8000,
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, addToast } = this.props;

    if (
      auth.token !== prevProps.auth.token &&
      [
        "totem-l",
        "monitor-l",
        "sportello-l",
        "ambulatoriale-light",
        "ambulatoriale-code",
        "ambulatoriale-code-full",
      ].indexOf(window.location.pathname.split("/")[1]) == -1
    ) {
      // logged in toast.
      if (auth.token) {
        addToast({
          title: "Accesso effettuato",
          content: (
            <>
              Benvenuto su <strong>Welcome</strong> gestore eliminacode. Ti
              auguriamo una buona permanenza 😀
            </>
          ),
          time: new Date(),
          duration: 4000,
        });

        // logged out toast.
      } else {
        addToast({
          title: "Logout effettuato",
          content: "Arrivederci!",
          time: new Date(),
          duration: 4000,
          color: "danger",
        });
      }
    }

    this.maybePrepareToastsTimeout();
  }

  maybePrepareToastsTimeout() {
    const { toasts } = this.props;

    const { timerToasts, hideToast } = this;

    Object.keys(toasts).forEach((uid) => {
      const toast = toasts[uid];

      if (toast.duration && !timerToasts[uid]) {
        timerToasts[uid] = true;
        setTimeout(() => {
          hideToast(uid);
        }, toast.duration);
      }
    });
  }

  hideToast(uid) {
    const { removeToast } = this.props;

    // hide toast.
    this.setState(
      {
        hiddenToasts: {
          ...this.state.hiddenToasts,
          [uid]: true,
        },
      },
      () => {
        // completely remove toast from store.
        setTimeout(() => {
          removeToast(uid);
        }, 600);
      }
    );
  }

  render() {
    const { toasts } = this.props;

    const { hiddenToasts } = this.state;

    return (
      <div className="rui-toast-container">
        {Object.keys(toasts)
          .reverse()
          .map((uid) => {
            const toast = toasts[uid];

            return (
              <Toast
                key={uid}
                className={classnames("rui-toast", `toast-${toast.color}`)}
                isOpen={!hiddenToasts[uid]}
              >
                <div className="toast-header">
                  {toast.title ? (
                    <h5 className="mr-auto mnb-2">{toast.title}</h5>
                  ) : (
                    ""
                  )}
                  {toast.time ? (
                    <small className="toast-date">
                      <TimeAgo date={toast.time} />
                    </small>
                  ) : (
                    ""
                  )}
                  {toast.closeButton ? (
                    <button
                      type="button"
                      className="ml-15 mnt-4 mnr-4 toast-close close"
                      aria-label="Close"
                      onClick={() => {
                        this.hideToast(uid);
                      }}
                    >
                      <Icon name="x" />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {toast.content ? <ToastBody>{toast.content}</ToastBody> : ""}
              </Toast>
            );
          })}
      </div>
    );
  }
}

export default connect(
  ({ auth, toasts }) => ({
    auth,
    toasts,
  }),
  {
    addToast: actionAddToast,
    removeToast: actionRemoveToast,
  }
)(PageToasts);
