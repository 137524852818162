const pendingRequests = new Map();
const defaultThrottlingTime = 3000;

/**
 * 
 * @param {Function} func 
 * @param {Number} wait Default value is 5 seconds
 * @returns {Promise} Returns a promise that resolves when the func parameter has been executed, or a new promise that waits for the previous one to finish
 */
const createThrottledRequest = (func, wait = defaultThrottlingTime) => {
    return (...args) => {
        const key = JSON.stringify(args);

        if (!pendingRequests.has(key)) {
            pendingRequests.set(key, { lastCallTime: 0, lastPromise: Promise.resolve(), functions: [] });
        }

        const request = pendingRequests.get(key);
        const now = Date.now();
        const remainingTime = wait - (now - request.lastCallTime);

        if (remainingTime <= 0 || remainingTime > wait) {
            request.lastCallTime = now;
            request.lastPromise = func(...args).finally(() => {
                pendingRequests.delete(key);
            });
            return request.lastPromise;
        } else {
            return new Promise((resolve, reject) => {
                request.functions.push({ resolve, reject });
                clearTimeout(request.timeout);
                request.timeout = setTimeout(() => {
                    request.lastCallTime = Date.now();
                    request.lastPromise = func(...args)
                        .then((result) => request.functions.forEach(({ resolve }) => resolve(result)))
                        .catch((result) => request.functions.forEach(({ reject }) => reject(result)))
                        .finally(() => {
                            pendingRequests.delete(key);
                        });
                }, remainingTime);
            });
        }
    };
};

export default createThrottledRequest;