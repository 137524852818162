/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";

/**
 * Internal Dependencies
 */

/**
 * Component
 */
class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hex: "#FFFFFF",
    };
  }

  componentDidMount() {
    if (this.props.color !== undefined) {
      this.setState({
        hex: this.props.color,
      });
    }
  }

  setColor(hex) {
    this.setState(
      {
        hex: hex,
      },
      () => this.props.setColor(hex)
    );
  }

  render() {
    const { hex } = this.state;
    return (
      <Fragment>
        <ChromePicker
          color={hex}
          onChangeComplete={({ hex }) => this.setColor(hex)}
        />
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(Content);
