import React, { Component } from "react";
import Clock from "react-live-clock";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { v4 as uuid } from "uuid";
import Icon from "../icon";

import Select from "react-select";
import Slideshow from "../slideshow";
import ButtonBody from "./button-body";
import ColorPicker from "./color-picker";
import ModalMedia from "./modal-media";
import "./style.css";
import TickerSettings from "./ticker-settings";
import { getPlaylists } from "../../services/playlistService";

class LayoutBuilder extends Component {
  constructor(props) {
    super(props);
    if (props.tipologia === "Totem") {
      this.state = {
        headerHeight: "20%",
        bodyHeight: "60%",
        footerHeight: "20%",
        colore_sfondo_pagina: "white",
        headerMedia: undefined,
        footerMedia: undefined,
        nome_pagina: "",
        body_img: undefined,
        button_margin: 0,
        tickerSettings: { value: "static", label: "Statico" },
        fonts: [
          { value: "Arial", label: "Arial" },
          { value: "Calibri", label: "Calibri" },
          { value: "Comic Sans MS", label: "Comic Sans MS" },
          { value: "Courier New", label: "Courier New" },
          { value: "Georgia", label: "Georgia" },
          { value: "Helvetica", label: "Helvetica" },
          { value: "Impact", label: "Impact" },
          { value: "Lucida Console", label: "Lucida Console" },
          { value: "Lucida Sans Unicode", label: "Lucida Sans Unicode" },
          { value: "Palatino Linotype", label: "Palatino Linotype" },
          { value: "Tahoma", label: "Tahoma" },
          { value: "Times New Roman", label: "Times New Roman" },
          { value: "Trebuchet MS", label: "Trebuchet MS" },
          { value: "Verdana", label: "Verdana" },
        ],
        twoColumns: false,
        multilingua: false,
        alarm: {
          position: "none",
          render: false,
          settings: {
            font: {},
            fontColor: undefined,
            bgColor: undefined,
            dimensioneRiga: undefined,
          },
        },
        buttons: [],
        playlists: [],
        isInitialLoadComplete: false,
      };
    } else {
      this.state = {
        headerHeight: "20%",
        bodyHeight: "60%",
        footerHeight: "20%",
        colore_sfondo_pagina: "white",
        headerMedia: undefined,
        footerMedia: undefined,
        body_img: undefined,
        tickerSettings: { value: "static", label: "Statico" },
        alarm: {
          position: "none",
          render: false,
          settings: {
            font: {},
            fontColor: undefined,
            bgColor: undefined,
            dimensioneRiga: undefined,
          },
        },
        font: { value: "Arial", label: "Arial" },
        fonts: [
          { value: "Arial", label: "Arial" },
          { value: "Calibri", label: "Calibri" },
          { value: "Comic Sans MS", label: "Comic Sans MS" },
          { value: "Courier New", label: "Courier New" },
          { value: "Georgia", label: "Georgia" },
          { value: "Helvetica", label: "Helvetica" },
          { value: "Impact", label: "Impact" },
          { value: "Lucida Console", label: "Lucida Console" },
          { value: "Lucida Sans Unicode", label: "Lucida Sans Unicode" },
          { value: "Palatino Linotype", label: "Palatino Linotype" },
          { value: "Tahoma", label: "Tahoma" },
          { value: "Times New Roman", label: "Times New Roman" },
          { value: "Trebuchet MS", label: "Trebuchet MS" },
          { value: "Verdana", label: "Verdana" },
        ],
        buttons: [],
        nameColonna1_c1: "Ticket",
        nameColonna2_c1: "Sportello",
        nameColonna1_c2: "Ticket",
        nameColonna2_c2: "Sportello",
        nameColonna1_c3: "Ticket",
        nameColonna2_c3: "Sportello",
        nameColonna1_c4: "Ticket",
        nameColonna2_c4: "Sportello",
        number_c1: -1,
        number_c2: -1,
        number_c3: -1,
        number_c4: -1,
        tipo_c1: "colonne",
        tipo_c2: "colonne",
        tipo_c3: "colonne",
        tipo_c4: "colonne",
        modalMediaTitle: "Media",
        media_c1: undefined,
        media_c2: undefined,
        media_c3: undefined,
        media_c4: undefined,
        playlists: [],
        isInitialLoadComplete: false,
      };
    }
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.setButtons = this.setButtons.bind(this);
  }

  onImgLoadHeader = ({ target: img }) => {
    let ratio = img.naturalWidth / img.naturalHeight;
    let finalHeight = img.width / ratio;
    this.setState({
      headerHeight: finalHeight,
    });
  };

  onImgLoadFooter = ({ target: img }) => {
    let ratio = img.naturalWidth / img.naturalHeight;
    let finalHeight = img.width / ratio;
    this.setState({
      footerHeight: finalHeight,
    });
  };

  async risolviMedia(nome_file, type = "Immagine") {
    return new Promise((resolve, reject) => {
      window.api.media
        .file(nome_file, type)
        .then((res) => {
          const file = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const fileURL = URL.createObjectURL(file);

          const media_object = {
            id: uuid(),
            type: "image",
            url: fileURL,
            name: nome_file,
          };

          resolve(media_object);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }

  async loadSingleMedia(media) {
    const fileResponse = await window.api.media.file(media.file, media.tipo);

    const file = new Blob([fileResponse.data], {
      type: "application/octet-stream",
    });

    const fileURL = URL.createObjectURL(file);
    let url_split = fileResponse.config.url.split("=");

    return {
      id: uuid(),
      tipo: url_split[url_split.length - 1],
      url: fileURL,
      name: media.file,
    };
  }

  async loadLayout() {
    let layout = this.props.layout;
    if (layout != undefined) {
      for (let [i, l] of Object.entries(layout)) {
        if (
          [
            "headerMedia",
            "footerMedia",
            "columns",
            "tickerSettings",
            "body_img",
          ].indexOf(i) > -1
        ) {
          if (i === "columns") {
            for (let [columnKey, columnData] of Object.entries(l)) {
              const stateUpdate = {
                [`number_${columnKey}`]: columnData[`number`],
                [`font_${columnKey}`]: columnData[`font`],
                [`fontColor_${columnKey}`]: columnData[`fontColor`],
                [`personalizzaBgColor_${columnKey}`]:
                  columnData[`personalizzaBgColor`],
                [`bgColor_${columnKey}`]: columnData[`bgColor`],
                [`chiamateColorate_${columnKey}`]:
                  columnData[`chiamateColorate`],
                [`dimensioneRiga_${columnKey}`]: columnData[`dimensioneRiga`],
                [`dimensioneIntestazione_${columnKey}`]:
                  columnData[`dimensioneIntestazione`],
                [`nameColonna1_${columnKey}`]:
                  columnData[`nameColonna1`] ?? "Ticket",
                [`nameColonna2_${columnKey}`]:
                  columnData[`nameColonna2`] ?? "Sportello",
                [`hideColonna1_${columnKey}`]:
                  columnData[`hideColonna1`] ?? false,
                [`hideColonna2_${columnKey}`]:
                  columnData[`hideColonna2`] ?? false,
                [`tipo_${columnKey}`]: columnData[`tipo`] ?? "colonne",
                [`numeroLampeggi_${columnKey}`]:
                  columnData[`numeroLampeggi`] ?? 5,
                [`tempoLampeggi_${columnKey}`]:
                  columnData[`tempoLampeggi`] ?? 5,
              };

              if (columnData.playlist) {
                stateUpdate[`playlist_${columnKey}_id`] = Number(
                  columnData.playlist
                );

                const playlist = this.state.playlists.find(
                  (p) => p.id === Number(columnData.playlist)
                );

                if (playlist) {
                  const downloadableMedia = [];
                  for (const media of playlist.media) {
                    const mediaResult = await this.loadSingleMedia(media);
                    downloadableMedia.push(mediaResult);
                  }
                  stateUpdate[`playlist_${columnKey}`] = downloadableMedia;
                }
              }

              this.setState(stateUpdate);
            }
          } else if (i === "tickerSettings") {
            this.setState({ [i]: l });
          } else {
            let res;
            if (l) {
              res = await this.risolviMedia(l);
            }
            if (["headerMedia", "footerMedia", "body_img"].indexOf(i) > -1) {
              this.setState({ [i]: res });
            } else {
              this.setState({ [i]: [res] });
            }
          }
        } else {
          this.setState({ [i]: l });
        }
      }
    }
  }

  async loadPlaylists() {
    const playlists = await getPlaylists();
    this.setState({ playlists });
  }

  async componentDidMount() {
    await this.loadPlaylists();
    await this.loadLayout();
    await this.setState({ isInitialLoadComplete: true });
  }

  handleChildUnmount() {
    this.setState({
      renderChild: false,
      renderChildTickerSettings: false,
      renderChildMedia: false,
    });
  }

  setColor = (hex) => {
    this.setState({
      colore_sfondo_pagina: hex,
      body_img: undefined,
    });
  };

  setTickerSettings = (settings) => {
    this.setState({
      tickerSettings: { ...settings },
    });
  };

  setMedia = (media) => {
    if (this.state.mediaType == "body_img") {
      this.setState({
        [this.state.mediaType]: media[0],
        colore_sfondo_pagina: "white",
      });
    } else if (["headerMedia", "footerMedia"].includes(this.state.mediaType)) {
      this.setState({
        [this.state.mediaType]: media[0],
      });
    } else {
      this.setState({
        [this.state.mediaType]: media,
      });
    }
  };

  setButtons = (buttons, indice) => {
    let bottoni = this.state.buttons;
    if (indice != -1) {
      bottoni[indice] = buttons;
    } else {
      bottoni = [];
      bottoni[0] = buttons;
    }
    this.setState({
      buttons: bottoni,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isInitialLoadComplete && prevState !== this.state) {
      let layout = {
        alarm: this.state.alarm,
        headerMedia: this.state.headerMedia,
        footerMedia: this.state.footerMedia,
        body_img: this.state.body_img,
        ticker: this.state.ticker,
        colore_sfondo_pagina: this.state.colore_sfondo_pagina,
        tickerSettings: this.state.tickerSettings,
      };
      if (this.props.tipologia === "Totem") {
        layout["buttons"] = this.state.buttons;
        layout["twoColumns"] = this.state.twoColumns;
        layout["multilingua"] = this.state.multilingua;
        layout["nome_pagina"] = this.state.nome_pagina;
        layout["button_margin"] = this.state.button_margin;
      } else {
        layout["columns"] = {
          c1: {},
          c2: {},
          c3: {},
          c4: {},
        };
        for (let [chiave, colonna] of Object.entries(layout["columns"])) {
          layout["columns"][chiave] = {
            number: this.state[`number_${chiave}`],
            font: this.state[`font_${chiave}`],
            fontColor: this.state[`fontColor_${chiave}`],
            dimensioneRiga: this.state[`dimensioneRiga_${chiave}`],
            nameColonna1: this.state[`nameColonna1_${chiave}`],
            nameColonna2: this.state[`nameColonna2_${chiave}`],
            hideColonna1: this.state[`hideColonna1_${chiave}`],
            hideColonna2: this.state[`hideColonna2_${chiave}`],
            personalizzaBgColor: this.state[`personalizzaBgColor_${chiave}`],
            chiamateColorate: this.state[`chiamateColorate_${chiave}`],
            bgColor: this.state[`bgColor_${chiave}`],
            bgRiga: this.state[`bgRiga_${chiave}`],
            tipo: this.state[`tipo_${chiave}`],
            playlist: this.state[`playlist_${chiave}_id`],
            dimensioneIntestazione:
              this.state[`dimensioneIntestazione_${chiave}`],
            numeroLampeggi: this.state[`numeroLampeggi_${chiave}`],
            tempoLampeggi: this.state[`tempoLampeggi_${chiave}`],
          };
        }
      }
      this.props.setLayout(layout, this.props.id);
    }
  }

  getPlaylistsSelectOptions = () => {
    return this.state.playlists.map((p) => {
      return { value: Number(p.id), label: p.titolo };
    });
  };

  handlePlaylistChange = async (chiave, e) => {
    const playlist = this.state.playlists.find((p) => p.id === e.value);
    const playlistMedia = [];
    for (const media of playlist.media) {
      const mediaResult = await this.loadSingleMedia(media);
      playlistMedia.push(mediaResult);
    }

    //CHANGE STATE FOR SLIDESHOW PREVIEW
    this.setState({
      [`playlist_${chiave}`]: playlistMedia,
      [`playlist_${chiave}_id`]: playlist.id,
    });
  };

  render() {
    const {
      colore_sfondo_pagina,
      tickerSettings,
      headerMedia,
      footerMedia,
      alarm,
      ticker,
      fonts,
      buttons,
      tipo_c1,
      tipo_c2,
      tipo_c3,
      tipo_c4,
      twoColumns,
    } = this.state;

    const playlistOptions = this.getPlaylistsSelectOptions();

    let media = { c1: {}, c2: {}, c3: {}, c4: {} };
    let colonne = { c1: {}, c2: {}, c3: {}, c4: {} };
    if (this.props.tipologia !== "Totem") {
      for (let [chiave, colonna] of Object.entries(media)) {
        if (this.state[`playlist_${chiave}`] != undefined) {
          media[chiave] = (
            <div
              className="d-flex flex-column"
              style={{ position: "relative", height: "calc(100% - 90px)" }}
            >
              {this.state.playlists && (
                <>
                  <Select
                    className="mb-15"
                    value={playlistOptions.find(
                      (p) => p.value === this.state[`playlist_${chiave}_id`]
                    )}
                    options={playlistOptions}
                    onChange={(e) => this.handlePlaylistChange(chiave, e)}
                  />
                  <Row className="align-items-center">
                    <Slideshow
                      showContainer={true}
                      media={this.state[`playlist_${chiave}`]}
                    />
                  </Row>
                </>
              )}

            </div>
          );
        } else {
          media[chiave] = this.state.playlists && (
            <Select
              className="mb-15 w-100"
              options={this.getPlaylistsSelectOptions()}
              onChange={(e) => this.handlePlaylistChange(chiave, e)}
            />
          );
        }
      }

      for (let [chiave, colonna] of Object.entries(colonne)) {
        colonne[chiave] = (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Limite Ticket</Label>
                  <Input
                    type="number"
                    required
                    value={this.state[`number_${chiave}`]}
                    placeholder="Numero righe"
                    onChange={(e) =>
                      this.setState({ [`number_${chiave}`]: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  checked={this.state[`hideColonna1_${chiave}`]}
                  type="checkbox"
                  onClick={(e) =>
                    this.setState({
                      [`hideColonna1_${chiave}`]: e.target.checked,
                    })
                  }
                />
                <Label for={`hideColonna1_${chiave}`}>Nascondi colonna 1</Label>
              </Col>
              <Col>
                <Input
                  checked={this.state[`hideColonna2_${chiave}`]}
                  type="checkbox"
                  onClick={(e) =>
                    this.setState({
                      [`hideColonna2_${chiave}`]: e.target.checked,
                    })
                  }
                />
                <Label for={`hideColonna2_${chiave}`}>Nascondi colonna 2</Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    required
                    value={this.state[`nameColonna1_${chiave}`]}
                    type="text"
                    placeholder="Nome Colonna 1"
                    onChange={(e) =>
                      this.setState({
                        [`nameColonna1_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    required
                    value={this.state[`nameColonna2_${chiave}`]}
                    type="text"
                    placeholder="Nome Colonna 2"
                    onChange={(e) =>
                      this.setState({
                        [`nameColonna2_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            Sfondo
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    checked={this.state[`personalizzaBgColor_${chiave}`]}
                    type="checkbox"
                    onClick={(e) =>
                      this.setState({
                        [`personalizzaBgColor_${chiave}`]: e.target.checked,
                      })
                    }
                  />
                  <Label for={`personalizzaBgColor_${chiave}`}>
                    Colore personalizzato
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="color"
                    placeholder="color placeholder"
                    value={this.state[`bgColor_${chiave}`]}
                    onChange={(e) =>
                      this.setState({ [`bgColor_${chiave}`]: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            Font righe
            <Row>
              <Col>
                <FormGroup>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Font righe..."
                    value={this.state[`font_${chiave}`]}
                    isSearchable={true}
                    options={fonts}
                    onChange={(e) =>
                      this.setState({
                        [`font_${chiave}`]: { value: e.value, label: e.label },
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="color"
                    placeholder="color placeholder"
                    value={this.state[`fontColor_${chiave}`]}
                    onChange={(e) =>
                      this.setState({ [`fontColor_${chiave}`]: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            Dimensioni
            <Row>
              <Col>
                <FormGroup>
                  <Label>Intestazione (px)</Label>
                  <Input
                    type="number"
                    value={this.state[`dimensioneIntestazione_${chiave}`]}
                    placeholder="Dimensione intestazione..."
                    onChange={(e) =>
                      this.setState({
                        [`dimensioneIntestazione_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Righe (px)</Label>
                  <Input
                    type="number"
                    value={this.state[`dimensioneRiga_${chiave}`]}
                    placeholder="Dimensione intestazione..."
                    onChange={(e) =>
                      this.setState({
                        [`dimensioneRiga_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            Lampeggi
            <Row>
              <Col>
                <FormGroup>
                  <Label>Quantità</Label>
                  <Input
                    required
                    value={this.state[`numeroLampeggi_${chiave}`]}
                    type="text"
                    placeholder="5"
                    onChange={(e) =>
                      this.setState({
                        [`numeroLampeggi_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Durata totale (sec)</Label>
                  <Input
                    required
                    value={this.state[`tempoLampeggi_${chiave}`]}
                    type="text"
                    placeholder="5"
                    onChange={(e) =>
                      this.setState({
                        [`tempoLampeggi_${chiave}`]: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            Sfondo
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    checked={this.state[`chiamateColorate_${chiave}`]}
                    type="checkbox"
                    onClick={(e) =>
                      this.setState({
                        [`chiamateColorate_${chiave}`]: e.target.checked,
                      })
                    }
                  />
                  <Label for={`chiamateColorate_${chiave}`}>
                    Chiamate colorate
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      }
    }

    return (
      <>
        {this.state.renderChild ? (
          <ColorPicker
            unmountMe={this.handleChildUnmount}
            setColor={this.setColor}
            selectedColor={colore_sfondo_pagina}
            componente="Sfondo Pagina"
          />
        ) : null}
        {this.state.renderChildTickerSettings ? (
          <TickerSettings
            unmountMe={this.handleChildUnmount}
            setTickerSettings={this.setTickerSettings}
            tickerSettings={tickerSettings}
          />
        ) : null}
        {this.state.renderChildMedia ? (
          <ModalMedia
            tipologia={this.props.tipologia}
            title={this.state.modalMediaTitle}
            unmountMe={this.handleChildUnmount}
            setMedia={this.setMedia}
          />
        ) : null}
        <div style={{ marginTop: "10px" }}>
          <Row>
            <Col lg="2" />
            <Col lg="8">
              <div
                className="welcome-preview-layout"
                style={
                  this.props.tipologia === "Totem"
                    ? { height: "450px", marginBottom: "350px" }
                    : {}
                }
              >
                <h3>Preview</h3>
                <Row>
                  {this.props.tipologia === "Totem" ? (
                    <>
                      <Col>
                        <Input
                          required
                          value={this.state[`nome_pagina`]}
                          type="text"
                          placeholder="Nome Pagina"
                          onChange={(e) =>
                            this.setState({ [`nome_pagina`]: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          checked={twoColumns}
                          type="checkbox"
                          name="twoColumns"
                          id="twoColumns"
                          onClick={(e) =>
                            this.setState({ twoColumns: e.target.checked })
                          }
                        />
                        <Label for="twoColumns"> 2 Colonne</Label>
                      </Col>
                      <Col>
                        <Input
                          checked={this.state.multilingua}
                          type="checkbox"
                          name="multilingua"
                          id="multilingua"
                          onClick={(e) =>
                            this.setState({ multilingua: e.target.checked })
                          }
                        />
                        <Label for="multilingua"> Selezionatore lingua</Label>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col>
                    <Button
                      size="lg"
                      color="brand"
                      className="btn-hover-outline btn-uniform btn-round"
                      onClick={() => this.setState({ renderChild: true })}
                    >
                      <Icon name="palette" vendor="tabler" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      color="brand"
                      className="btn-hover-outline btn-uniform btn-round"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          renderChildMedia: true,
                          mediaType: "body_img",
                          modalMediaTitle: "Body Background",
                        })
                      }
                    >
                      <Icon name="photo" vendor="tabler" />
                    </Button>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "grid",
                    flexDirection: "column",
                    alignItems: "left",
                    minHeight: "20%",
                    backgroundColor: colore_sfondo_pagina,
                  }}
                >
                  <div
                    className="layer1"
                    style={{
                      border: "1px solid black",
                      borderBottom: "0",
                      borderRight: "0",
                      zIndex: 10,
                      height: "100%",
                      width: "15%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {alarm.position === "top-left" && alarm.render ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() => {
                            let orologioConfig = this.state.alarm.settings;
                            this.setState({
                              alarm: {
                                position: "top-left",
                                render: false,
                                settings: orologioConfig,
                              },
                            });
                          }}
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <Clock
                          format={"HH:mm"}
                          ticking={true}
                          timezone={"Europe/Rome"}
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          let orologioConfig = this.state.alarm.settings;
                          this.setState({
                            alarm: {
                              position: "top-left",
                              render: true,
                              settings: orologioConfig,
                            },
                          });
                        }}
                      >
                        <Icon name="alarm" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                  <div
                    className="layer2"
                    style={{
                      border: "1px solid black",
                      borderBottom: "0",
                      margin: 0,
                      height: "100%",
                      marginLeft: "15%",
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {[undefined, false].indexOf(headerMedia) === -1 ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() =>
                            this.setState({ headerMedia: undefined })
                          }
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <img
                          src={headerMedia.url}
                          className="welcome-img-drag"
                          alt="banner"
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() =>
                          this.setState({
                            renderChildMedia: true,
                            mediaType: "headerMedia",
                            modalMediaTitle: "Media",
                          })
                        }
                      >
                        <Icon name="photo" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                  <div
                    className="layer3"
                    style={{
                      border: "1px solid black",
                      borderBottom: "0",
                      borderLeft: "0",
                      zIndex: 10,
                      height: "100%",
                      width: "15%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {alarm.position === "top-right" && alarm.render ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() => {
                            let orologioConfig = this.state.alarm.settings;
                            this.setState({
                              alarm: {
                                position: "top-right",
                                render: false,
                                settings: orologioConfig,
                              },
                            });
                          }}
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <Clock
                          format={"HH:mm"}
                          ticking={true}
                          timezone={"Europe/Rome"}
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          let orologioConfig = this.state.alarm.settings;
                          this.setState({
                            alarm: {
                              position: "top-right",
                              render: true,
                              settings: orologioConfig,
                            },
                          });
                        }}
                      >
                        <Icon name="alarm" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: this.props.tipologia !== "Totem" ? "grid" : "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    backgroundColor: colore_sfondo_pagina,
                  }}
                >
                  {this.props.tipologia === "Totem" ? (
                    <>
                      <div
                        className="layerA"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          borderTop: "1px solid black",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                          <Label>Margine bottoni dall&apos;alto (px)</Label>
                          <Input
                            type="number"
                            value={this.state.button_margin}
                            placeholder="margine bottoni (px)"
                            onChange={(e) =>
                              this.setState({ button_margin: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <ButtonBody
                        setButtons={this.setButtons}
                        pagine={this.props.pagine}
                        tipologia={this.props.tipologia}
                        buttons={buttons}
                        twoColumns={twoColumns}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="layer6"
                        style={{
                          border: "1px solid black",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          height: "100%",
                          width: "50%",
                        }}
                      >
                        <Row style={{ marginTop: "25px" }}>
                          <Col>
                            <CustomInput
                              checked={tipo_c1 == "colonne" ? true : false}
                              onChange={() =>
                                this.setState({
                                  tipo_c1: "colonne",
                                  media_c1: undefined,
                                })
                              }
                              type="radio"
                              id="colonne_c1"
                              label="Colonne"
                            />
                          </Col>
                          <Col>
                            <CustomInput
                              checked={tipo_c1 == "media" ? true : false}
                              onChange={() =>
                                this.setState({ tipo_c1: "media" })
                              }
                              type="radio"
                              id="media_c1"
                              label="Slideshow"
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        {tipo_c1 === "colonne" ? colonne.c1 : media.c1}
                      </div>
                      <div
                        className="layer7"
                        style={{
                          border: "1px solid black",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          borderLeft: "0px",
                          height: "100%",
                          width: "50%",
                        }}
                      >
                        <Row style={{ marginTop: "25px" }}>
                          <Col>
                            <CustomInput
                              checked={tipo_c2 == "colonne" ? true : false}
                              onChange={() =>
                                this.setState({
                                  tipo_c2: "colonne",
                                  media_c2: undefined,
                                })
                              }
                              type="radio"
                              id="colonne_c2"
                              label="Colonne"
                            />
                          </Col>
                          <Col>
                            <CustomInput
                              checked={tipo_c2 == "media" ? true : false}
                              onChange={() =>
                                this.setState({ tipo_c2: "media" })
                              }
                              type="radio"
                              id="media_c2"
                              label="Slideshow"
                            />
                          </Col>
                        </Row>
                        <hr></hr>
                        {tipo_c2 === "colonne" ? colonne.c2 : media.c2}
                      </div>
                    </>
                  )}
                </div>
                {this.props.tipologia !== "Totem" ? (
                  <>
                    <div
                      style={{
                        display: "grid",
                        flexDirection: "column",
                        alignItems: "left",
                        height: "110%",
                        flexWrap: this.props.tipologia == "Totem" ? "wrap" : "",
                        justifyContent:
                          this.props.tipologia == "Totem" ? "center" : "",
                        backgroundColor: colore_sfondo_pagina,
                      }}
                    >
                      <>
                        <div
                          className="layer6"
                          style={{
                            border: "1px solid black",
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            borderTop: "0px",
                            height: "auto",
                            width: "50%",
                          }}
                        >
                          <Row style={{ marginTop: "25px" }}>
                            <Col>
                              <CustomInput
                                checked={tipo_c3 == "colonne" ? true : false}
                                onChange={() =>
                                  this.setState({
                                    tipo_c3: "colonne",
                                    media_c3: undefined,
                                  })
                                }
                                type="radio"
                                id="colonne_c3"
                                label="Colonne"
                              />
                            </Col>
                            <Col>
                              <CustomInput
                                checked={tipo_c3 == "media" ? true : false}
                                onChange={() =>
                                  this.setState({ tipo_c3: "media" })
                                }
                                type="radio"
                                id="media_c3"
                                label="Slideshow"
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                          {tipo_c3 === "colonne" ? colonne.c3 : media.c3}
                        </div>
                        <div
                          className="layer7"
                          style={{
                            border: "1px solid black",
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            borderLeft: "0px",
                            borderTop: "0px",
                            height: "100%",
                            width: "50%",
                          }}
                        >
                          <Row style={{ marginTop: "25px" }}>
                            <Col>
                              <CustomInput
                                checked={tipo_c4 == "colonne" ? true : false}
                                onChange={() =>
                                  this.setState({
                                    tipo_c4: "colonne",
                                    media_c4: undefined,
                                  })
                                }
                                type="radio"
                                id="colonne_c4"
                                label="Colonne"
                              />
                            </Col>
                            <Col>
                              <CustomInput
                                checked={tipo_c4 == "media" ? true : false}
                                onChange={() =>
                                  this.setState({ tipo_c4: "media" })
                                }
                                type="radio"
                                id="media_c4"
                                label="Slideshow"
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                          {tipo_c4 === "colonne" ? colonne.c4 : media.c4}
                        </div>
                      </>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  className="layerA"
                  style={{
                    border: "1px solid black",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    borderTop: "0px",
                    width: "100%",
                  }}
                >
                  <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
                    Ticker
                  </h4>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Font</Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Font..."
                          value={
                            this.state.tickerSettings
                              ? this.state.tickerSettings.font
                              : undefined
                          }
                          isSearchable={true}
                          options={fonts}
                          onChange={(e) => {
                            let tickerSet = this.state.tickerSettings;
                            tickerSet.font = { value: e.value, label: e.label };
                            this.setState({ tickerSettings: tickerSet });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Colore</Label>
                        <Input
                          type="color"
                          placeholder="color placeholder"
                          value={
                            this.state.tickerSettings
                              ? this.state.tickerSettings.fontColor
                              : undefined
                          }
                          onChange={(e) => {
                            let tickerSet = this.state.tickerSettings;
                            tickerSet.fontColor = e.target.value;
                            this.setState({ tickerSettings: tickerSet });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Dimensione (px)</Label>
                        <Input
                          type="number"
                          value={
                            this.state.tickerSettings
                              ? this.state.tickerSettings.dimensioneRiga
                              : undefined
                          }
                          placeholder="Dimensione (px)"
                          onChange={(e) => {
                            let tickerSet = this.state.tickerSettings;
                            tickerSet.dimensioneRiga = e.target.value;
                            this.setState({ tickerSettings: tickerSet });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Sfondo</Label>
                        <Input
                          type="color"
                          placeholder="color placeholder"
                          value={
                            this.state.tickerSettings
                              ? this.state.tickerSettings.bgColor
                              : undefined
                          }
                          onChange={(e) => {
                            let tickerSet = this.state.tickerSettings;
                            tickerSet.bgColor = e.target.value;
                            this.setState({ tickerSettings: tickerSet });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 style={{ textAlign: "center", marginTop: 0 }}>
                    Orologio
                  </h4>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Font</Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Font..."
                          value={
                            this.state.alarm.settings
                              ? this.state.alarm.settings.font
                              : undefined
                          }
                          isSearchable={true}
                          options={fonts}
                          onChange={(e) => {
                            let orologioConfig = this.state.alarm;
                            console.log(orologioConfig);
                            orologioConfig.settings.font = {
                              value: e.value,
                              label: e.label,
                            };
                            this.setState({ alarm: orologioConfig });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Colore</Label>
                        <Input
                          type="color"
                          placeholder="color placeholder"
                          value={
                            this.state.alarm.settings
                              ? this.state.alarm.settings.fontColor
                              : undefined
                          }
                          onChange={(e) => {
                            let orologioConfig = this.state.alarm;
                            orologioConfig.settings.fontColor = e.target.value;
                            this.setState({ alarm: orologioConfig });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Dimensione (px)</Label>
                        <Input
                          type="number"
                          value={
                            this.state.alarm.settings
                              ? this.state.alarm.settings.dimensioneRiga
                              : undefined
                          }
                          placeholder="Dimensione (px)"
                          onChange={(e) => {
                            let orologioConfig = this.state.alarm;
                            orologioConfig.settings.dimensioneRiga =
                              e.target.value;
                            this.setState({ alarm: orologioConfig });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Sfondo</Label>
                        <Input
                          type="color"
                          placeholder="color placeholder"
                          value={
                            this.state.alarm.settings
                              ? this.state.alarm.settings.bgColor
                              : undefined
                          }
                          onChange={(e) => {
                            let orologioConfig = this.state.alarm;
                            orologioConfig.settings.bgColor = e.target.value;
                            this.setState({ alarm: orologioConfig });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    height: "15%",
                    backgroundColor: colore_sfondo_pagina,
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      borderTop: "0",
                      borderBottom: "0",
                      marginTop: 0,
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {ticker ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() => this.setState({ ticker: false })}
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <Button
                          size="md"
                          className="btn-uniform"
                          onClick={() =>
                            this.setState({ renderChildTickerSettings: true })
                          }
                        >
                          <Icon name="settings" vendor="tabler" />
                        </Button>
                        {tickerSettings.tipologia ? (
                          <>
                            {tickerSettings.tipologia.value !== "url" ? (
                              <h1>{tickerSettings.static_text}</h1>
                            ) : tickerSettings.url !== undefined ? (
                              <h1>{tickerSettings.url}</h1>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          this.setState({
                            ticker: true,
                            renderChildTickerSettings: true,
                          });
                        }}
                      >
                        <Icon name="text-direction-ltr" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    flexDirection: "column",
                    alignItems: "left",
                    height: "20%",
                    backgroundColor: colore_sfondo_pagina,
                  }}
                >
                  <div
                    className="layer1"
                    style={{
                      border: "1px solid black",
                      borderRight: "0",
                      height: "100%",
                      width: "15%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {alarm.position === "bottom-left" && alarm.render ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() => {
                            let orologioConfig = this.state.alarm.settings;
                            this.setState({
                              alarm: {
                                position: "bottom-left",
                                render: false,
                                settings: orologioConfig,
                              },
                            });
                          }}
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <Clock
                          format={"HH:mm"}
                          ticking={true}
                          timezone={"Europe/Rome"}
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          let orologioConfig = this.state.alarm.settings;
                          this.setState({
                            alarm: {
                              position: "bottom-left",
                              render: true,
                              settings: orologioConfig,
                            },
                          });
                        }}
                      >
                        <Icon name="alarm" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                  <div
                    className="layer2"
                    style={{
                      border: "1px solid black",
                      margin: 0,
                      height: "100%",
                      marginLeft: "15%",
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {[undefined, false].indexOf(footerMedia) === -1 ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() =>
                            this.setState({ footerMedia: undefined })
                          }
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <img
                          src={footerMedia.url}
                          className="welcome-img-drag"
                          alt="banner"
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() =>
                          this.setState({
                            renderChildMedia: true,
                            mediaType: "footerMedia",
                            modalMediaTitle: "Media",
                          })
                        }
                      >
                        <Icon name="photo" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                  <div
                    className="layer3"
                    style={{
                      border: "1px solid black",
                      borderLeft: "0",
                      height: "100%",
                      width: "15%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {alarm.position === "bottom-right" && alarm.render ? (
                      <>
                        <Button
                          className="clear-badge btn-uniform"
                          onClick={() => {
                            let orologioConfig = this.state.alarm.settings;
                            this.setState({
                              alarm: {
                                position: "bottom-right",
                                render: false,
                                settings: orologioConfig,
                              },
                            });
                          }}
                        >
                          <span role="img" aria-label="cross">
                            ❌
                          </span>
                        </Button>
                        <Clock
                          format={"HH:mm"}
                          ticking={true}
                          timezone={"Europe/Rome"}
                        />
                      </>
                    ) : (
                      <Button
                        color={colore_sfondo_pagina}
                        className="btn-uniform"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "table-cell",
                          verticalAlign: "middle",
                        }}
                        onClick={() => {
                          let orologioConfig = this.state.alarm.settings;
                          this.setState({
                            alarm: {
                              position: "bottom-right",
                              render: true,
                              settings: orologioConfig,
                            },
                          });
                        }}
                      >
                        <Icon name="alarm" vendor="tabler" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="2" />
          </Row>
        </div>
      </>
    );
  }
}

export default LayoutBuilder;
