/**
 * App Settings
 */
const settings = {
  night_mode: localStorage.getItem("night_mode") == "true" ? true : false,
  spotlight_mode:
    localStorage.getItem("spotlight_mode") == "true" ? true : false,
  show_section_lines:
    localStorage.getItem("show_section_lines") == "true" ? true : false,
  sidebar_dark: localStorage.getItem("sidebar_dark") == "true" ? true : false,
  sidebar_static:
    localStorage.getItem("sidebar_static") == "true" ? true : false,
  sidebar_small: false,
  sidebar_effect: "shrink",
  nav: true,
  nav_dark: false,
  nav_logo_path: require("../assets/images/logo_welcome.png"),
  nav_logo_white_path: require("../assets/images/logo_welcome.png"),
  nav_logo_width: "130px",
  login_logo_width: "250px",
  nav_logo_url: "/",
  nav_align: "left",
  nav_expand: "lg",
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  nome_ambiente: "Monitoraggio",
  nome_sportello_web: "Sportello Web",
  nome_sportello_light: "Sportello Ambulatoriale Light",
  nome_sportello_code: "Sportello Ambulatoriale Code",
  nome_sportello_code_full: "Sportello Ambulatoriale Code Full",
  home_url: "/",
  navigation: {},
  navigation_sidebar: {
    // Strumenti
    "/monitoraggio": {
      label: "Strumenti",
      name: "Monitoraggio",
      icon: "heart-rate-monitor",
    },
    "/grafici": {
      name: "Grafici",
      icon: "chart-line",
    },
    "/statistiche": {
      name: "Statistiche",
      icon: "file-export",
    },

    //sportelli
    "/web": {
      label: "Sportelli",
      name: "Sportelli Live",
      icon: "building-store",
      sub: {
        "/web": {
          name: "Web",
        },
        "/ambulatoriale-light": {
          name: "Ambulatoriale Light",
        },
        "/ambulatoriale-code": {
          name: "Ambulatoriale Code",
        },
        "/ambulatoriale-code-full": {
          name: "Ambulatoriale Code Full",
        },
      },
    },

    // Amministratore
    "/ambienti": {
      label: "Admin",
      name: "Ambienti",
      icon: "world",
    },
    "/code": {
      name: "Code",
      icon: "list-numbers",
    },
    "/sportelli": {
      name: "Sportelli",
      icon: "building-store",
      sub: {
        "/sportelli": {
          name: "Dispositivi",
          icon: "list",
        },
        "/policy": {
          name: "Policy",
          icon: "puzzle",
        },
        "/prestazioni": {
          name: "Prestazioni",
          icon: "stethoscope",
        },
      },
    },
    "/totem": {
      name: "Totem",
      icon: "device-tablet",
      sub: {
        "/totem": {
          name: "Dispositivi",
          icon: "list",
        },
        "/t-ticket": {
          name: "Ticket",
          icon: "ticket",
        },
        "/t-bottoni": {
          name: "Bottoni",
          icon: "circle",
        },
        "/t-layout": {
          name: "Layout",
          icon: "layout",
        },
      },
    },
    "/monitor": {
      name: "Monitor",
      icon: "device-desktop",
      sub: {
        "/monitor": {
          name: "Dispositivi",
          icon: "list",
        },
        "/m-layout": {
          name: "Layout",
          icon: "layout",
        },
      },
    },
    "/display": {
      name: "Display",
      icon: "browser",
    },
    "/scanner": {
      name: "Scanner",
      icon: "qrcode",
    },
    "/schedulazioni": {
      name: "Schedulazioni",
      icon: "calendar",
    },
    "/playlist": {
      name: "Playlist",
      icon: "list",
    },
    "/media": {
      name: "Media",
      icon: "photo",
    },
    "/utenti": {
      name: "Utenti",
      icon: "users",
    },
    "/privacy": {
      name: "Privacy",
      icon: "file-info",
    },
    "/permessi": {
      name: "Permessi",
      icon: "key",
    },
    "/impostazioni": {
      name: "Impostazioni",
      icon: "settings",
    },
  },
  breadcrumbs_presets: {
    apps: {
      "/mailbox": "Mailbox",
      "/messenger": "Messenger",
      "/calendar": "Calendar",
      "/project-management": "Project Management",
      "/file-manager": "File Manager",
      "/profile": "Profile",
    },
    content: {
      "/grid": "Grid",
      "/colors": "Colors",
      "/typography": "Typography",
      "/component-table": "Tables",
    },
    components_base: {
      "/component-alert": "Alert",
      "/component-badge": "Badge",
      "/component-breadcrumbs": "Breadcrumbs",
      "/component-button": "Button",
      "/component-card": "Card",
      "/component-carousel": "Carousel",
      "/component-collapse": "Collapse",
      "/component-dropdown": "Dropdown",
      "/component-list-group": "List Group",
      "/component-media-object": "Media Object",
      "/component-modal": "Modal",
      "/component-nav": "Nav",
      "/component-pagination": "Pagination",
      "/component-popover": "Popover",
      "/component-progress": "Progress",
      "/component-spinner": "Spinner",
      "/component-tabs": "Tabs",
    },
    components_advanced: {
      "/component-icons-feather": "Icons: Feather",
      "/component-icons-fontawesome": "Icons: Font Awesome",
      "/component-charts-chartjs": "Charts: Chart.js",
      "/component-charts-chartist": "Charts: Chartist",
      "/component-charts-peity": "Charts: Peity",
      "/component-charts-echarts": "Charts: eCharts",
      "/component-charts-flot": "Charts: Flot",
      "/component-timeline": "Timeline",
      "/component-tree-view": "Tree View",
      "/component-toast": "Toast",
      "/component-sweet-alert": "Sweet Alert",
      "/component-maps": "Maps",
      "/component-data-tables": "Data Tables",
    },
    forms_advanced: {
      "/forms-datetime": "DateTime Picker",
      "/forms-validation": "Validation",
      "/forms-touch-spin": "Touch Spin",
      "/forms-range-slider": "Range Slider",
      "/forms-input-masks": "Input Masks",
      "/forms-dropzone": "Dropzone",
      "/forms-colorpicker": "Color Picker",
      "/forms-select": "Select",
      "/forms-markdown": "Markdown editor",
      "/forms-wysiwyg": "WYSIWYG editor",
    },
  },
  img_country: {
    ac: require("../assets/images/country_flags/ac.svg"),
    ad: require("../assets/images/country_flags/ad.svg"),
    ae: require("../assets/images/country_flags/ae.svg"),
    af: require("../assets/images/country_flags/af.svg"),
    ag: require("../assets/images/country_flags/ag.svg"),
    ai: require("../assets/images/country_flags/ai.svg"),
    al: require("../assets/images/country_flags/al.svg"),
    am: require("../assets/images/country_flags/am.svg"),
    ao: require("../assets/images/country_flags/ao.svg"),
    aq: require("../assets/images/country_flags/aq.svg"),
    ar: require("../assets/images/country_flags/ar.svg"),
    as: require("../assets/images/country_flags/as.svg"),
    at: require("../assets/images/country_flags/at.svg"),
    au: require("../assets/images/country_flags/au.svg"),
    aw: require("../assets/images/country_flags/aw.svg"),
    ax: require("../assets/images/country_flags/ax.svg"),
    az: require("../assets/images/country_flags/az.svg"),
    ba: require("../assets/images/country_flags/ba.svg"),
    bb: require("../assets/images/country_flags/bb.svg"),
    bd: require("../assets/images/country_flags/bd.svg"),
    be: require("../assets/images/country_flags/be.svg"),
    bf: require("../assets/images/country_flags/bf.svg"),
    bg: require("../assets/images/country_flags/bg.svg"),
    bh: require("../assets/images/country_flags/bh.svg"),
    bi: require("../assets/images/country_flags/bi.svg"),
    bj: require("../assets/images/country_flags/bj.svg"),
    bl: require("../assets/images/country_flags/bl.svg"),
    bm: require("../assets/images/country_flags/bm.svg"),
    bn: require("../assets/images/country_flags/bn.svg"),
    bo: require("../assets/images/country_flags/bo.svg"),
    bq: require("../assets/images/country_flags/bq.svg"),
    br: require("../assets/images/country_flags/br.svg"),
    bs: require("../assets/images/country_flags/bs.svg"),
    bt: require("../assets/images/country_flags/bt.svg"),
    bv: require("../assets/images/country_flags/bv.svg"),
    bw: require("../assets/images/country_flags/bw.svg"),
    by: require("../assets/images/country_flags/by.svg"),
    bz: require("../assets/images/country_flags/bz.svg"),
    ca: require("../assets/images/country_flags/ca.svg"),
    cc: require("../assets/images/country_flags/cc.svg"),
    cd: require("../assets/images/country_flags/cd.svg"),
    ce: require("../assets/images/country_flags/cefta.svg"),
    cf: require("../assets/images/country_flags/cf.svg"),
    cg: require("../assets/images/country_flags/cg.svg"),
    ch: require("../assets/images/country_flags/ch.svg"),
    ci: require("../assets/images/country_flags/ci.svg"),
    ck: require("../assets/images/country_flags/ck.svg"),
    cl: require("../assets/images/country_flags/cl.svg"),
    cm: require("../assets/images/country_flags/cm.svg"),
    cn: require("../assets/images/country_flags/cn.svg"),
    co: require("../assets/images/country_flags/co.svg"),
    cp: require("../assets/images/country_flags/cp.svg"),
    cr: require("../assets/images/country_flags/cr.svg"),
    cu: require("../assets/images/country_flags/cu.svg"),
    cv: require("../assets/images/country_flags/cv.svg"),
    cw: require("../assets/images/country_flags/cw.svg"),
    cx: require("../assets/images/country_flags/cx.svg"),
    cy: require("../assets/images/country_flags/cy.svg"),
    cz: require("../assets/images/country_flags/cz.svg"),
    de: require("../assets/images/country_flags/de.svg"),
    dg: require("../assets/images/country_flags/dg.svg"),
    dj: require("../assets/images/country_flags/dj.svg"),
    dk: require("../assets/images/country_flags/dk.svg"),
    dm: require("../assets/images/country_flags/dm.svg"),
    do: require("../assets/images/country_flags/do.svg"),
    dz: require("../assets/images/country_flags/dz.svg"),
    ea: require("../assets/images/country_flags/ea.svg"),
    ec: require("../assets/images/country_flags/ec.svg"),
    ee: require("../assets/images/country_flags/ee.svg"),
    eg: require("../assets/images/country_flags/eg.svg"),
    eh: require("../assets/images/country_flags/eh.svg"),
    er: require("../assets/images/country_flags/er.svg"),
    es: require("../assets/images/country_flags/es.svg"),
    en: require("../assets/images/country_flags/gb.svg"),
    et: require("../assets/images/country_flags/et.svg"),
    eu: require("../assets/images/country_flags/eu.svg"),
    fi: require("../assets/images/country_flags/fi.svg"),
    fj: require("../assets/images/country_flags/fj.svg"),
    fk: require("../assets/images/country_flags/fk.svg"),
    fm: require("../assets/images/country_flags/fm.svg"),
    fo: require("../assets/images/country_flags/fo.svg"),
    fr: require("../assets/images/country_flags/fr.svg"),
    ga: require("../assets/images/country_flags/ga.svg"),
    gb: require("../assets/images/country_flags/gb.svg"),
    gd: require("../assets/images/country_flags/gd.svg"),
    ge: require("../assets/images/country_flags/ge.svg"),
    gf: require("../assets/images/country_flags/gf.svg"),
    gg: require("../assets/images/country_flags/gg.svg"),
    gh: require("../assets/images/country_flags/gh.svg"),
    gi: require("../assets/images/country_flags/gi.svg"),
    gl: require("../assets/images/country_flags/gl.svg"),
    gm: require("../assets/images/country_flags/gm.svg"),
    gn: require("../assets/images/country_flags/gn.svg"),
    gp: require("../assets/images/country_flags/gp.svg"),
    gq: require("../assets/images/country_flags/gq.svg"),
    gr: require("../assets/images/country_flags/gr.svg"),
    gs: require("../assets/images/country_flags/gs.svg"),
    gt: require("../assets/images/country_flags/gt.svg"),
    gu: require("../assets/images/country_flags/gu.svg"),
    gw: require("../assets/images/country_flags/gw.svg"),
    gy: require("../assets/images/country_flags/gy.svg"),
    hk: require("../assets/images/country_flags/hk.svg"),
    hm: require("../assets/images/country_flags/hm.svg"),
    hn: require("../assets/images/country_flags/hn.svg"),
    hr: require("../assets/images/country_flags/hr.svg"),
    ht: require("../assets/images/country_flags/ht.svg"),
    hu: require("../assets/images/country_flags/hu.svg"),
    ic: require("../assets/images/country_flags/ic.svg"),
    id: require("../assets/images/country_flags/id.svg"),
    ie: require("../assets/images/country_flags/ie.svg"),
    il: require("../assets/images/country_flags/il.svg"),
    im: require("../assets/images/country_flags/im.svg"),
    in: require("../assets/images/country_flags/in.svg"),
    io: require("../assets/images/country_flags/io.svg"),
    iq: require("../assets/images/country_flags/iq.svg"),
    ir: require("../assets/images/country_flags/ir.svg"),
    is: require("../assets/images/country_flags/is.svg"),
    it: require("../assets/images/country_flags/it.svg"),
    je: require("../assets/images/country_flags/je.svg"),
    jm: require("../assets/images/country_flags/jm.svg"),
    jo: require("../assets/images/country_flags/jo.svg"),
    jp: require("../assets/images/country_flags/jp.svg"),
    ke: require("../assets/images/country_flags/ke.svg"),
    kg: require("../assets/images/country_flags/kg.svg"),
    kh: require("../assets/images/country_flags/kh.svg"),
    ki: require("../assets/images/country_flags/ki.svg"),
    km: require("../assets/images/country_flags/km.svg"),
    kn: require("../assets/images/country_flags/kn.svg"),
    kp: require("../assets/images/country_flags/kp.svg"),
    kr: require("../assets/images/country_flags/kr.svg"),
    kw: require("../assets/images/country_flags/kw.svg"),
    ky: require("../assets/images/country_flags/ky.svg"),
    kz: require("../assets/images/country_flags/kz.svg"),
    la: require("../assets/images/country_flags/la.svg"),
    lb: require("../assets/images/country_flags/lb.svg"),
    lc: require("../assets/images/country_flags/lc.svg"),
    li: require("../assets/images/country_flags/li.svg"),
    lk: require("../assets/images/country_flags/lk.svg"),
    lr: require("../assets/images/country_flags/lr.svg"),
    ls: require("../assets/images/country_flags/ls.svg"),
    lt: require("../assets/images/country_flags/lt.svg"),
    lu: require("../assets/images/country_flags/lu.svg"),
    lv: require("../assets/images/country_flags/lv.svg"),
    ly: require("../assets/images/country_flags/ly.svg"),
    ma: require("../assets/images/country_flags/ma.svg"),
    mc: require("../assets/images/country_flags/mc.svg"),
    md: require("../assets/images/country_flags/md.svg"),
    me: require("../assets/images/country_flags/me.svg"),
    mf: require("../assets/images/country_flags/mf.svg"),
    mg: require("../assets/images/country_flags/mg.svg"),
    mh: require("../assets/images/country_flags/mh.svg"),
    mk: require("../assets/images/country_flags/mk.svg"),
    ml: require("../assets/images/country_flags/ml.svg"),
    mm: require("../assets/images/country_flags/mm.svg"),
    mn: require("../assets/images/country_flags/mn.svg"),
    mo: require("../assets/images/country_flags/mo.svg"),
    mp: require("../assets/images/country_flags/mp.svg"),
    mq: require("../assets/images/country_flags/mq.svg"),
    mr: require("../assets/images/country_flags/mr.svg"),
    ms: require("../assets/images/country_flags/ms.svg"),
    mt: require("../assets/images/country_flags/mt.svg"),
    mu: require("../assets/images/country_flags/mu.svg"),
    mv: require("../assets/images/country_flags/mv.svg"),
    mw: require("../assets/images/country_flags/mw.svg"),
    mx: require("../assets/images/country_flags/mx.svg"),
    my: require("../assets/images/country_flags/my.svg"),
    mz: require("../assets/images/country_flags/mz.svg"),
    na: require("../assets/images/country_flags/na.svg"),
    nc: require("../assets/images/country_flags/nc.svg"),
    ne: require("../assets/images/country_flags/ne.svg"),
    nf: require("../assets/images/country_flags/nf.svg"),
    ng: require("../assets/images/country_flags/ng.svg"),
    ni: require("../assets/images/country_flags/ni.svg"),
    nl: require("../assets/images/country_flags/nl.svg"),
    no: require("../assets/images/country_flags/no.svg"),
    np: require("../assets/images/country_flags/np.svg"),
    nr: require("../assets/images/country_flags/nr.svg"),
    nu: require("../assets/images/country_flags/nu.svg"),
    nz: require("../assets/images/country_flags/nz.svg"),
    om: require("../assets/images/country_flags/om.svg"),
    pa: require("../assets/images/country_flags/pa.svg"),
    pe: require("../assets/images/country_flags/pe.svg"),
    pf: require("../assets/images/country_flags/pf.svg"),
    pg: require("../assets/images/country_flags/pg.svg"),
    ph: require("../assets/images/country_flags/ph.svg"),
    pk: require("../assets/images/country_flags/pk.svg"),
    pl: require("../assets/images/country_flags/pl.svg"),
    pm: require("../assets/images/country_flags/pm.svg"),
    pn: require("../assets/images/country_flags/pn.svg"),
    pr: require("../assets/images/country_flags/pr.svg"),
    ps: require("../assets/images/country_flags/ps.svg"),
    pt: require("../assets/images/country_flags/pt.svg"),
    pw: require("../assets/images/country_flags/pw.svg"),
    py: require("../assets/images/country_flags/py.svg"),
    qa: require("../assets/images/country_flags/qa.svg"),
    re: require("../assets/images/country_flags/re.svg"),
    ro: require("../assets/images/country_flags/ro.svg"),
    rs: require("../assets/images/country_flags/rs.svg"),
    ru: require("../assets/images/country_flags/ru.svg"),
    rw: require("../assets/images/country_flags/rw.svg"),
    sa: require("../assets/images/country_flags/sa.svg"),
    sb: require("../assets/images/country_flags/sb.svg"),
    sc: require("../assets/images/country_flags/sc.svg"),
    sd: require("../assets/images/country_flags/sd.svg"),
    se: require("../assets/images/country_flags/se.svg"),
    sg: require("../assets/images/country_flags/sg.svg"),
    sh: require("../assets/images/country_flags/sh.svg"),
    si: require("../assets/images/country_flags/si.svg"),
    sj: require("../assets/images/country_flags/sj.svg"),
    sk: require("../assets/images/country_flags/sk.svg"),
    sl: require("../assets/images/country_flags/sl.svg"),
    sm: require("../assets/images/country_flags/sm.svg"),
    sn: require("../assets/images/country_flags/sn.svg"),
    so: require("../assets/images/country_flags/so.svg"),
    sr: require("../assets/images/country_flags/sr.svg"),
    ss: require("../assets/images/country_flags/ss.svg"),
    st: require("../assets/images/country_flags/st.svg"),
    sv: require("../assets/images/country_flags/sv.svg"),
    sx: require("../assets/images/country_flags/sx.svg"),
    sy: require("../assets/images/country_flags/sy.svg"),
    sz: require("../assets/images/country_flags/sz.svg"),
    ta: require("../assets/images/country_flags/ta.svg"),
    tc: require("../assets/images/country_flags/tc.svg"),
    td: require("../assets/images/country_flags/td.svg"),
    tf: require("../assets/images/country_flags/tf.svg"),
    tg: require("../assets/images/country_flags/tg.svg"),
    th: require("../assets/images/country_flags/th.svg"),
    tj: require("../assets/images/country_flags/tj.svg"),
    tk: require("../assets/images/country_flags/tk.svg"),
    tl: require("../assets/images/country_flags/tl.svg"),
    tm: require("../assets/images/country_flags/tm.svg"),
    tn: require("../assets/images/country_flags/tn.svg"),
    to: require("../assets/images/country_flags/to.svg"),
    tr: require("../assets/images/country_flags/tr.svg"),
    tt: require("../assets/images/country_flags/tt.svg"),
    tv: require("../assets/images/country_flags/tv.svg"),
    tw: require("../assets/images/country_flags/tw.svg"),
    tz: require("../assets/images/country_flags/tz.svg"),
    ua: require("../assets/images/country_flags/ua.svg"),
    ug: require("../assets/images/country_flags/ug.svg"),
    um: require("../assets/images/country_flags/um.svg"),
    un: require("../assets/images/country_flags/un.svg"),
    us: require("../assets/images/country_flags/us.svg"),
    uy: require("../assets/images/country_flags/uy.svg"),
    uz: require("../assets/images/country_flags/uz.svg"),
    va: require("../assets/images/country_flags/va.svg"),
    vc: require("../assets/images/country_flags/vc.svg"),
    ve: require("../assets/images/country_flags/ve.svg"),
    vg: require("../assets/images/country_flags/vg.svg"),
    vi: require("../assets/images/country_flags/vi.svg"),
    vn: require("../assets/images/country_flags/vn.svg"),
    vu: require("../assets/images/country_flags/vu.svg"),
    wf: require("../assets/images/country_flags/wf.svg"),
    ws: require("../assets/images/country_flags/ws.svg"),
    xk: require("../assets/images/country_flags/xk.svg"),
    xx: require("../assets/images/country_flags/xx.svg"),
    ye: require("../assets/images/country_flags/ye.svg"),
    yt: require("../assets/images/country_flags/yt.svg"),
    za: require("../assets/images/country_flags/za.svg"),
    zm: require("../assets/images/country_flags/zm.svg"),
    zw: require("../assets/images/country_flags/zw.svg"),
  },
  img_file: {
    empty: require("../assets/images/icon-empty.svg"),
    zip: require("../assets/images/icon-zip.svg"),
    rar: require("../assets/images/icon-rar.svg"),
    html: require("../assets/images/icon-html.svg"),
    php: require("../assets/images/icon-php.svg"),
    css: require("../assets/images/icon-css.svg"),
    js: require("../assets/images/icon-js.svg"),
    doc: require("../assets/images/icon-doc.svg"),
    txt: require("../assets/images/icon-txt.svg"),
    pdf: require("../assets/images/icon-pdf.svg"),
    xls: require("../assets/images/icon-xls.svg"),
    png: require("../assets/images/icon-png.svg"),
    jpg: require("../assets/images/icon-jpg.svg"),
    clearInputIcon: require("../assets/images/icon-clear-input.svg"),
    closeIcon: require("../assets/images/icon-close.svg"),
    confirmIcon: require("../assets/images/icon-confirm.svg"),
    rightAngleBracketIcon: require("../assets/images/icon-right-angle-bracket.svg")
  },
  users: [
    {
      img: require("../assets/images/avatar-1.png"),
      img_profile: require("../assets/images/avatar-1-profile.png"),
      name: "Jack Boyd",
    },
    {
      img: require("../assets/images/avatar-2.png"),
      name: "Helen Holt",
    },
    {
      img: require("../assets/images/avatar-3.png"),
      name: "Avice Harris",
    },
    {
      img: require("../assets/images/avatar-4.png"),
      name: "Anna Rice",
    },
    {
      img: require("../assets/images/avatar-5.png"),
      name: "Amber Smith",
    },
    {
      img: require("../assets/images/avatar-6.png"),
      name: "Mary Rose",
    },
  ],
  letters: [
    {
      img: require("../assets/images/letter-1.png"),
      img_min: require("../assets/images/letter-1-min.png"),
    },
    {
      img: require("../assets/images/letter-2.png"),
      img_min: require("../assets/images/letter-2-min.png"),
    },
    {
      img: require("../assets/images/letter-3.png"),
      img_min: require("../assets/images/letter-3-min.png"),
    },
    {
      img: require("../assets/images/letter-4.png"),
      img_min: require("../assets/images/letter-4-min.png"),
    },
  ],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === "1";

  switch (name) {
    case "setting-night-mode":
      settings.night_mode = isTrue;
      break;
    case "setting-show-section-lines":
      settings.show_section_lines = isTrue;
      break;
    case "setting-sidebar-small":
      settings.sidebar_small = isTrue;
      break;
    case "setting-sidebar-dark":
      settings.sidebar_dark = isTrue;
      break;
    case "setting-nav-dark":
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
