import React, { Component } from "react";
import { Carousel, CarouselItem, Button } from "reactstrap";
import { MEDIA_TYPE } from "./constant";
import Icon from "../icon";

const DEFAULT_SLIDE_IMAGE_DURATION = 10000;
const BOX_HEIGHT = 500;
const PADDING = 20;

const mediaStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: `calc(100% - ${PADDING * 2}px)`,
  maxHeight: `calc(100% - ${PADDING * 2}px)`,
  objectFit: "contain",
};

const fullSizeMediaStyle = {
  width: "100%",
  objectFit: "contain",
  borderRadius: "5px",
};

const buttonStyle = {
  width: "50px",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.media.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.media.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { showButtons, showContainer, media } = this.props;

    const currentItem = media[activeIndex];
    const interval =
      currentItem?.tipo === MEDIA_TYPE.IMMAGINE
        ? DEFAULT_SLIDE_IMAGE_DURATION
        : null;

    const slides = media.map((item) => (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={item.url}
      >
        <div
          style={
            showContainer
              ? {
                  height: `${BOX_HEIGHT}px`,
                  width: "100%",
                  position: "relative",
                  textAlign: "center",
                }
              : {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }
        >
          {item.tipo === MEDIA_TYPE.IMMAGINE ? (
            <img
              src={item.url}
              alt={item.name}
              style={showContainer ? mediaStyle : fullSizeMediaStyle}
            />
          ) : (
            <video
              key={`${item.url}-${activeIndex}`}
              autoPlay
              muted
              onEnded={this.next}
              style={showContainer ? mediaStyle : fullSizeMediaStyle}
            >
              <source src={item.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </CarouselItem>
    ));
    return (
      <div style={{ width: "100%" }}>
        <div
          style={
            showContainer
              ? {
                  backgroundColor: "#eee",
                  width: "100%",
                  marginBottom: "20px",
                  overflow: "hidden",
                }
              : { width: "100%" }
          }
        >
          <Carousel
            ride="carousel"
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
            interval={interval}
            style={{
              width: "100%",
            }}
          >
            {slides}
          </Carousel>
        </div>
        {showButtons && media.length > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Button
              onClick={this.previous}
              color="brand"
              className="btn btn-uniform btn-round"
              style={buttonStyle}
            >
              <Icon name="chevron-left" />
            </Button>
            <Button
              onClick={this.next}
              color="brand"
              className="btn btn-uniform btn-round"
              style={buttonStyle}
            >
              <Icon name="chevron-right" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default Slideshow;
