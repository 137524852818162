/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

/**
 * Internal Dependencies
 */
import Icon from "../icon";
/* import Carousel from './carousel'; */

/**
 * Component
 */
class TickerSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
      tipologia: { value: "url", label: "Url" },
      tipologie: [
        { value: "url", label: "Url" },
        { value: "static_text", label: "Statico" },
      ],
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.dismiss();
    this.setState({
      modalOpen: false,
    });
  }

  dismiss() {
    this.props.unmountMe();
  }

  submitForm = (e) => {
    e.preventDefault();
    let settings = {};
    if (this.state.tipologia?.value == "url") {
      settings = {
        tipologia: this.state.tipologia,
        url: this.state.url,
        static_text: undefined,
      };
    } else {
      settings = {
        tipologia: this.state.tipologia,
        url: undefined,
        static_text: this.state.static_text,
      };
    }

    this.props.setTickerSettings({ ...this.props.tickerSettings, ...settings });
    this.dismiss();
  };

  componentDidMount() {
    if (this.props.tickerSettings.tipologia !== undefined) {
      this.setState({
        tipologia: this.props.tickerSettings.tipologia,
        url: this.props.tickerSettings.url,
        static_text: this.props.tickerSettings.static_text,
      });
    }
  }

  render() {
    const { tipologia, tipologie, url, static_text } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          fade
          backdrop="static"
          size="md"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title h2">Proprietà Ticker</h5>
            <Button className="close" color="" onClick={this.toggle}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Form id="form" onSubmit={this.submitForm}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="tipologia">Tipologia</Label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Tipologia..."
                      value={tipologia}
                      isSearchable={true}
                      options={tipologie}
                      onChange={(e) =>
                        this.setState({
                          tipologia: { value: e.value, label: e.label },
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {tipologia.value === "url" ? (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="url">URL</Label>
                      <Input
                        required
                        value={url}
                        type="text"
                        name="url"
                        id="url"
                        placeholder="URL Feed"
                        onChange={(e) => this.setState({ url: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : tipologia.value === "static_text" ? (
                <Row>
                  <Col>
                    <FormGroup style={{ marginTop: "15px" }}>
                      <Label for="static_text">Testo Statico</Label>
                      <Input
                        required
                        defaultValue={static_text}
                        type="textarea"
                        name="static_text"
                        id="static_text"
                        placeholder="Inserire Testo"
                        onChange={(e) =>
                          this.setState({ static_text: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Annulla
            </Button>{" "}
            <Button form="form" color="brand">
              Modifica
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(({ settings }) => ({
  settings,
}))(TickerSettings);
