import axios from "axios";
import dotenv from "dotenv";
import Swal from "sweetalert2";
import createThrottledRequest from "./throttler";
dotenv.config();

const api = axios.create();
api.defaults.baseURL = window.REACT_APP_API_ENDPOINT;
api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["accept"] = "application/json";
api.defaults.withCredentials = true;

let refreshTokenPromise;

const getRefreshToken = (_originalRequest) =>
  api
    .get(
      (process.env.REACT_APP_MODE === "production" ? "/api" : "") +
        "/auth/refreshtoken"
    )
    .then((res) => {
      if (res.status === 201) {
        return res;
      }
    });

api.interceptors.request.use(
  function (config) {
    refreshTokenPromise = null; // clear state
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error == "Error: Network Error") {
      Swal.fire("Errore nel contattare il server API, riprova più tardi.");
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url ===
        (process.env.REACT_APP_MODE === "production" ? "/api" : "") +
          "/auth/refreshtoken"
    ) {
      await window.store.dispatch({
        type: "UPDATE_AUTH",
        auth: {
          token: "",
        },
      });
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!refreshTokenPromise) {
        // check for an existing in-progress request
        originalRequest._retry = true;
        // if nothing is in-progress, start a new refresh token request
        refreshTokenPromise = getRefreshToken(originalRequest).then(() => {
          refreshTokenPromise = null; // clear state
        });
      }

      return refreshTokenPromise.then((_token) => {
        window.store.dispatch({
          type: "UPDATE_AUTH",
          auth: {
            token: true,
          },
        });
        return api(originalRequest);
      });
    }
    return Promise.reject(error);
  }
);

const throttledApiGet = createThrottledRequest((url) => {
  return api.get(url);
});

export const internal_api = {
  error: {
    log: (body) => {
      return api.post("/error/log", body);
    },
  },

  ticket: {
    servizi: {
      elencoSportello: (id_sportello, filtro, id_prestazione) => {
        return throttledApiGet(
          `/ticket/servizi/elenco-sportello/${id_sportello}/${filtro}${
            id_prestazione ? `/${id_prestazione}` : ""
          }`
        );
      },
      stato: (body) => {
        return api.post("/ticket/servizi/cambia-stato", body);
      },
      next: (body) => {
        return api.post("/ticket/servizi/prossimo-ticket", body);
      },
      ultimo: (body) => {
        return api.post("/ticket/servizi/ultimo", body);
      },
      chiamataManuale: (body) => {
        return api.post("/ticket/servizi/chiamata-manuale", body);
      },
      tabellaTicketStatistiche: (body) => {
        return api.post(
          `/ticket/servizi/tabellaTicketStatistiche${
            body.currentPage && body.currentPage > 1
              ? `/${body.currentPage}`
              : ""
          }`,
          body
        );
      },
      statsTicket: (body) => {
        return api.post("/ticket/servizi/statsTicket", body);
      },
      statsAmbienti: (body) => {
        return api.post("/ticket/servizi/statsAmbienti", body);
      },
    },
    stati: () => {
      return api.get("/ticket/stati");
    },
    storico: (idTicket) => {
      return api.get(`/ticket/storico/${idTicket}`);
    },
    elenco: () => {
      return api.get("/ticket/elenco");
    },
    dati: (id) => {
      return api.get("/ticket/dati/" + id);
    },
    inserisci: (body) => {
      return api.post("/ticket/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/ticket/modifica", body);
    },
    elimina: (body) => {
      return api.post("/ticket/elimina", body);
    },
    tabella: () => {
      return api.get("/ticket/tabella");
    },
    prenotazione: {
      dati: (idTicket) => {
        return api.get("/ticket/prenotazione/dati/" + idTicket);
      },
      modifica: (body) => {
        return api.post("/ticket/prenotazione/modifica", body);
      },
    },
  },

  auth: {
    username: () => {
      return api.get("/auth/username");
    },
    permessi: () => {
      return api.get("/auth/permessi");
    },
    signin: (body) => {
      return api.post("/auth/signin", body);
    },
    totem_signin: (body) => {
      return api.post("/auth/totem/signin", body);
    },
    monitor_signin: (body) => {
      return api.post("/auth/monitor/signin", body);
    },
    bloccaCode: () => {
      return api.get("/auth/blocca-code");
    },
    refreshtoken: () => {
      return api.get("/auth/refreshtoken");
    },
    loggedIn: () => {
      return api.get("/auth/loggedin");
    },
    logout: () => {
      return api.post("/auth/logout");
    },
    cambiaPassword: (body) => {
      return api.post("/auth/cambia-password", body);
    },
    cambiaPasswordAuth: (body) => {
      return api.post("/auth/cambia-password-auth", body);
    },
    resetPassword: (body) => {
      return api.post("/auth/reset-password", body);
    },
    checkToken: (token) => {
      return api.get("/auth/check-reset-token/" + token);
    },
    sendResetLink: (body) => {
      return api.post("/auth/send-reset-link", body);
    },
    getPolicy: () => {
      return api.post("/auth/policy");
    },
    enablePolicy: () => {
      return api.post("/auth/enablePolicy");
    },
    checkExportGlobalData: () => {
      return api.get("/auth/checkExportGlobalData");
    },
  },

  privacy: {
    dati: () => {
      return api.get("/privacy/dati");
    },
    inserisci: (body) => {
      return api.post("/privacy/inserisci", body);
    },
  },

  schedulazioni: {
    tabella: () => {
      return api.get("/schedulazioni/tabella");
    },
    dati: (id) => {
      return api.get("/schedulazioni/dati/" + id);
    },
    inserisci: (body) => {
      return api.post("/schedulazioni/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/schedulazioni/modifica", body);
    },
    elimina: (body) => {
      return api.post("/schedulazioni/elimina", body);
    },
    elenco: () => {
      return api.get("/schedulazioni/elenco");
    },
  },

  ambienti: {
    elenco: () => {
      return api.get("/ambienti/elenco");
    },
    monitoraggio: () => {
      return api.get("/ambienti/monitoraggio");
    },
    mediaAttesa: (ambiente = undefined) => {
      return api.get(
        "/ambienti/mediaAttesa" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    inCoda: (id_ambiente) => {
      return api.get("/ambienti/inCoda/" + id_ambiente);
    },
    sportelliOnline: (id_ambiente) => {
      return api.get("/ambienti/sportelliOnline/" + id_ambiente);
    },
    accodati: (id_ambiente) => {
      return api.get("/ambienti/accodati/" + id_ambiente);
    },
    media: (id_ambiente) => {
      return api.get("/ambienti/tempo_medio?id_ambiente=" + id_ambiente);
    },
    tabella: () => {
      return api.get("/ambienti/tabella");
    },
    dati: (id) => {
      return api.get("/ambienti/dati/" + id);
    },
    inserisci: (body) => {
      return api.post("/ambienti/inserisci", body);
    },
    modifica: async (body) => {
      return await api.post("/ambienti/modifica", body);
    },
    elimina: (body) => {
      return api.post("/ambienti/elimina", body);
    },
    cambiaStato: (body) => {
      return api.post("/ambienti/cambia-stato", body);
    },
    riavvioDispositivo: (body) => {
      return api.post("/ambienti/monitoraggio/riavvio", body);
    },
    elencoAmbientiUtente: () => {
      return api.get("/ambienti/elencoPerUtente");
    },
  },
  locale: {
    elenco: (completo) => {
      let filtro = completo ? "?disponibili=true" : "";
      return api.get("/locale/elenco" + filtro);
    },
    assegna: (body) => {
      return api.post("/locale/cambia-stato", body);
    },
    utente: (body) => {
      return api.post("/locale/lingue-utente", body);
    },
  },
  risorse: {
    elenco: (id_gruppo = undefined) => {
      let gruppo = id_gruppo != undefined ? "?id_gruppo=" + id_gruppo : "";
      return api.get("/risorse/elenco" + gruppo);
    },
    assegna: (body) => {
      return api.post("/risorse/assegna", body);
    },
    pathAuth: (url) => {
      return api.get("/risorse/pathAuth?url=" + url);
    },
    featureAuth: (feature) => {
      return api.get("/risorse/featureAuth?feature=" + feature);
    },
  },
  code: {
    elenco: (id_ambiente = undefined) => {
      return api.get("/code/elenco/" + id_ambiente);
    },
    elencoTotale: () => {
      return api.get("/code/elencoTotale");
    },
    tabella: () => {
      return api.get("/code/tabella");
    },
    dati: (id) => {
      return api.get("/code/dati/" + id);
    },
    inserisci: (body) => {
      return api.post("/code/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/code/modifica", body);
    },
    elimina: (body) => {
      return api.post("/code/elimina", body);
    },
    blocca: (body) => {
      return api.post("/code/blocca", body);
    },
    cambiaStato: (body) => {
      return api.post("/code/cambia-stato", body);
    },
  },
  totem: {
    funzione: (totem) => {
      return api.get("/totem/funzione/" + totem);
    },
    pageInfo: (totem) => {
      return api.get("/totem/layout/pageInfo/" + totem);
    },
    pageError: (totem) => {
      return api.get("/totem/layout/pageError/" + totem);
    },
    hasScanner: (id) => {
      return api.get("/totem/hasScanner/" + id);
    },
    idCoda: (id_layout_bottone) => {
      return api.get("/totem/idCoda/" + id_layout_bottone);
    },
    checkConnesso: (totem) => {
      return api.get("/totem/check_connesso/" + totem);
    },
    ricarica: () => {
      return api.post("/totem/ricarica");
    },
    nOnline: (ambiente = undefined) => {
      return api.get(
        "/totem/nOnline" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    elenco: (ambiente = undefined) => {
      return api.get(
        "/totem/elenco" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    tabella: () => {
      return api.get("/totem/tabella");
    },
    dati: (id_totem) => {
      return api.get("/totem/dati/" + id_totem);
    },
    inserisci: (body) => {
      return api.post("/totem/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/totem/modifica", body);
    },
    elimina: (body) => {
      return api.post("/totem/elimina", body);
    },
    initPrinterState: (id_totem, revision_id) => {
      return api.get(
        "/totem/servizi/initPrinterState/" + id_totem + "&" + revision_id
      );
    },
    cambiaStato: (body) => {
      return api.post("/totem/cambia-stato", body);
    },
    codaSbloccata: (id_totem, coda) => {
      return api.get("/totem/codaSbloccata/" + id_totem + "&" + coda);
    },

    ticket: {
      accoda: (body) => {
        return api.post("/totem/ticket/accoda", body);
      },
      infoTicket: (body) => {
        return api.post("/totem/ticket/InfoTicket", body);
      },
    },

    layout: {
      tabella: () => {
        return api.get("/totem/layout/tabella");
      },
      lista: () => {
        return api.get("/totem/layout/lista");
      },
      dati: (id_layout) => {
        return api.get("/totem/layout/dati/" + id_layout);
      },
      load: (id_layout) => {
        return api.get("/totem/layout/load/" + id_layout);
      },
      preview: () => {
        return api.get("/totem/layout/preview");
      },
      aggiungi: (body) => {
        return api.post("/totem/layout/aggiungi", body);
      },
      modifica: (body) => {
        return api.post("/totem/layout/modifica", body);
      },
      elimina: (body) => {
        return api.post("/totem/layout/elimina", body);
      },
      header: (id_totem) => {
        return api.get("/totem/layout/header/" + id_totem, {
          responseType: "blob",
        });
      },
      body: (id_totem) => {
        return api.get("/totem/layout/body/" + id_totem);
      },
      footer: (id_totem) => {
        return api.get("/totem/layout/footer/" + id_totem, {
          responseType: "blob",
        });
      },
    },

    bottoni: {
      elenco: () => {
        return api.get("/totem/bottoni/elenco");
      },
      assegnati: (totem) => {
        return api.get("/totem/bottoni/assegnati/" + totem);
      },
      tabella: () => {
        return api.get("/totem/bottoni/tabella");
      },
      dati: (id) => {
        return api.get("/totem/bottoni/dati/" + id);
      },
      traduzioni: (id) => {
        return api.get("/totem/bottoni/traduzioni/" + id);
      },
      traduci: (body) => {
        return api.post("/totem/bottoni/traduci", body);
      },
      inserisci: (body) => {
        return api.post("/totem/bottoni/inserisci", body);
      },
      modifica: (body) => {
        return api.post("/totem/bottoni/modifica", body);
      },
      elimina: (body) => {
        return api.post("/totem/bottoni/elimina", body);
      },
    },
  },

  monitor: {
    elenco: (ambiente) => {
      return api.get(
        "/monitor/elenco" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    checkConnesso: (monitor, forceSocketConnection = null) => {
      return api.get(
        `/monitor/check_connesso/${monitor}${
          forceSocketConnection ? `?forceSocketConnection=1` : ""
        }`
      );
    },
    header: (id_monitor) => {
      return api.get("/monitor/header/" + id_monitor);
    },
    tabella: () => {
      return api.get("/monitor/tabella");
    },
    dati: (id_monitor) => {
      return api.get("/monitor/dati/" + id_monitor);
    },
    nOnline: (ambiente = undefined) => {
      return api.get(
        "/monitor/nOnline" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    sportelli_assegnati: (id_monitor) => {
      return api.get("/monitor/sportelli-assegnati/" + id_monitor);
    },
    inserisci: (body) => {
      return api.post("/monitor/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/monitor/modifica", body);
    },
    elimina: (body) => {
      return api.post("/monitor/elimina", body);
    },
    audio: (id_monitor) => {
      return api.get("/monitor/audio/" + id_monitor);
    },
    immagine: (id_monitor) => {
      return api.get("/monitor/immagine/" + id_monitor);
    },
    cambiaStato: (body) => {
      return api.post("/monitor/cambia-stato", body);
    },

    layout: {
      tabella: () => {
        return api.get("/monitor/layout/tabella");
      },
      elenco: () => {
        return api.get("/monitor/layout/lista");
      },
      dati: (id_layout) => {
        return api.get("/monitor/layout/dati/" + id_layout);
      },
      aggiungi: (body) => {
        return api.post("/monitor/layout/aggiungi", body);
      },
      modifica: (body) => {
        return api.post("/monitor/layout/modifica", body);
      },
      elimina: (body) => {
        return api.post("/monitor/layout/elimina", body);
      },
      load: (id_layout) => {
        return api.get("/monitor/layout/load/" + id_layout);
      },
      traduzioni: (id) => {
        return api.get("/monitor/layout/traduzioni/" + id);
      },
      traduci: (body) => {
        return api.post("/monitor/layout/traduci", body);
      },
    },
  },

  display: {
    elenco: (ambiente) => {
      return api.get(
        "/display/elenco" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    checkConnesso: (display, forceSocketConnection = null) => {
      return api.get(
        `/display/check_connesso/${display}${
          forceSocketConnection ? `?forceSocketConnection=1` : ""
        }`
      );
    },
    tabella: () => {
      return api.get("/display/tabella");
    },
    dati: (id_display) => {
      return api.get("/display/" + id_display);
    },
    inserisci: (body) => {
      return api.post("/display", body);
    },
    modifica: (body) => {
      return api.put("/display", body);
    },
    elimina: (id) => {
      return api.delete("/display/" + id);
    },
    cambiaStato: (body) => {
      return api.patch("/display", body);
    },
    stato: (body) => {
      return api.patch("/display/stato", body);
    },
    getStato: (idSportello) => {
      return api.get("/display/stato/" + idSportello);
    },
    layout: (id_display) => {
      return api.get("/display/layout/" + id_display);
    },
  },

  prestazioni: {
    elenco: () => {
      return api.get("/prestazioni/elenco");
    },
    tabella: () => {
      return api.get("/prestazioni/tabella");
    },
    dati: (id) => {
      return api.get("/prestazioni/" + id);
    },
    inserisci: (body) => {
      return api.post("/prestazioni", body);
    },
    modifica: (body) => {
      return api.put("/prestazioni", body);
    },
    elimina: (id) => {
      return api.delete("/prestazioni/" + id);
    },
  },

  sportelli: {
    id: () => {
      return api.get("/sportelli/id");
    },
    elenco: (id_ambiente) => {
      return api.get(
        "/sportelli/elenco" +
          (id_ambiente != undefined ? "/" + id_ambiente : "")
      );
    },
    elencoConnessi: () => {
      return api.get("/sportelli/elenco_connessi");
    },
    card: (id_ambiente) => {
      return api.get(
        "/sportelli/card" + (id_ambiente != undefined ? "/" + id_ambiente : "")
      );
    },
    nOnline: (ambiente = undefined) => {
      return api.get(
        "/sportelli/nOnline" + (ambiente != undefined ? "/" + ambiente : "")
      );
    },
    elencoAssegnati: (ambiente) => {
      return api.get("/sportelli/elenco_assegnati/" + ambiente);
    },
    code: (id_sportello) => {
      return api.get(`/sportelli/code/${id_sportello}`);
    },
    inCoda: (id_sportello) => {
      return throttledApiGet(`/sportelli/inCoda/${id_sportello}`);
    },
    bloccati: (id_sportello) => {
      return throttledApiGet(`/sportelli/bloccati/${id_sportello}`);
    },
    tabella: () => {
      return api.get("/sportelli/tabella");
    },
    dati: (id_sportello) => {
      return api.get("/sportelli/dati/" + id_sportello);
    },
    inserisci: (body) => {
      return api.post("/sportelli/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/sportelli/modifica", body);
    },
    elimina: (body) => {
      return api.post("/sportelli/elimina", body);
    },
    cambiaStato: (body) => {
      return api.post("/sportelli/cambia-stato", body);
    },
    canSeeBloccate: (sportello) => {
      return api.get("/sportelli/canSeeBloccate/" + sportello);
    },
    canManageChiamataSingola: (sportello) => {
      return api.get("/sportelli/canManageChiamataSingola/" + sportello);
    },
    haveDisplay: (sportello) => {
      return api.get("/sportelli/haveDisplay/" + sportello);
    },
    prestazioni: (sportello) => {
      return api.get("/sportelli/prestazioni/" + sportello);
    },
    getLogoutTimeout: (sportello) => {
      return api.get("/sportelli/getLogoutTimeout/" + sportello);
    },
    messaggio: (body) => {
      return api.post("/sportelli/monitoraggio/messaggio", body);
    },
    activeUser: (body) => {
      return api.post("/sportelli/activeUser", body);
    },
  },

  policy: {
    elenco: (sportello) => {
      return api.get(
        "/policy/elenco" + (sportello != undefined ? "/" + sportello : "")
      );
    },
    dati: (id) => {
      return api.get("/policy/dati/" + id);
    },
    tabella: (body) => {
      return api.get("/policy/tabella");
    },
    inserisci: (body) => {
      return api.post("/policy/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/policy/modifica", body);
    },
    elimina: (body) => {
      return api.post("/policy/elimina", body);
    },
  },

  logiche: {
    tabella: (id_logica) => {
      return api.get("/logiche/tabella/" + id_logica);
    },
    aggiorna: (body) => {
      return api.post("/logiche/aggiorna", body);
    },
  },

  scanner: {
    elenco: () => {
      return api.get("/scanner/elenco");
    },
    tabella: () => {
      return api.get("/scanner/tabella");
    },
    dati: (id_scanner) => {
      return api.get("/scanner/dati/" + id_scanner);
    },
    inserisci: (body) => {
      return api.post("/scanner/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/scanner/modifica", body);
    },
    elimina: (body) => {
      return api.post("/scanner/elimina", body);
    },
    cambiaStato: (body) => {
      return api.post("/scanner/cambia-stato", body);
    },
    sendData: (body) => {
      return api.post("/scanner/sendData", body);
    },
    getStati: (dispositivo) => {
      return api.get("/scanner/getStati/" + dispositivo);
    },
  },

  media: {
    upload: (body) => {
      return api.post("/media/carica", body);
    },
    elimina: (body) => {
      return api.post("/media/elimina", body);
    },
    immagini: (categoria = undefined) => {
      return api.get(`/media/elenco/Immagine/${categoria}`);
    },
    video: (categoria = undefined) => {
      return api.get(`/media/elenco/Video/${categoria}`);
    },
    audio: (categoria = undefined) => {
      return api.get("/media/elenco/Audio/" + categoria);
    },
    file: (nome_file, tipologia) => {
      return api.get(
        "/media/file?nome_file=" + nome_file + "&tipo=" + tipologia,
        { responseType: "blob" }
      );
    },
    listaPlaylist: (nome_file) => {
      return api.get("/media/lista-playlist?file=" + nome_file);
    },
  },

  utenti: {
    elenco: () => {
      return api.get("/auth/elenco-utenti");
    },
    tabella: () => {
      return api.get("/auth/tabella-utenti");
    },
    dati: (id) => {
      return api.get("/auth/dati-utente/" + id);
    },
    lingue: (id) => {
      return api.get("/auth/lingue-utente/" + id);
    },
    inserisci: (body) => {
      return api.post("/auth/signup", body);
    },
    modifica: (body) => {
      return api.post("/auth/modifica-utente", body);
    },
    elimina: (body) => {
      return api.post("/auth/elimina", body);
    },
    cambiaStato: (body) => {
      return api.post("/auth/cambia-stato", body);
    },
  },

  permessi: {
    carica: () => {
      return api.get("/permessi/carica");
    },
    aggiungi: (body) => {
      return api.post("/permessi/aggiungi", body);
    },
    modifica: (body) => {
      return api.post("/permessi/modifica", body);
    },
    elimina: (body) => {
      return api.post("/permessi/elimina", body);
    },
  },

  gruppi: {
    elenco: () => {
      return api.get("/gruppi/elenco");
    },
    aggiungi: (body) => {
      return api.post("/gruppi/aggiungi", body);
    },
    modifica: (body) => {
      return api.post("/gruppi/modifica", body);
    },
    elimina: (body) => {
      return api.post("/gruppi/elimina", body);
    },
  },

  messaggi: {
    massivo: (body) => {
      return api.post("/messaggi/massivo", body);
    },
  },

  rimandi: {
    code: (rimando, coda = null) => {
      return api.get(`/rimandi/code/${rimando}/${coda}`);
    },
    move: (body) => {
      return api.post("/rimandi/move", body);
    },
  },

  statistiche: {
    export: (params) => {
      return api.get("/external/esportaStatistiche?" + $.param(params), {
        responseType: "blob",
      });
    },
    exportStorico: (params) => {
      return api.get("/external/esportaStoricoStatistiche?" + $.param(params), {
        responseType: "blob",
      });
    },
  },
  reset: {
    tabella: (tipologia) => {
      return api.get("/reset/tabella/" + tipologia);
    },
    dati: (id) => {
      return api.get("/reset/dati/" + id);
    },
    inserisci: (body) => {
      return api.post("/reset/inserisci", body);
    },
    modifica: (body) => {
      return api.post("/reset/modifica", body);
    },
    elimina: (body) => {
      return api.post("/reset/elimina", body);
    },
    cambiaStato: (body) => {
      return api.post("/reset/cambia-stato", body);
    },
  },

  rss: {
    getFeed: (body) => {
      return api.post("/rss/getFeed", body);
    },
  },
  playlist: {
    list: (params = {}) => {
      return api.get(
        `/playlist` +
          (Object.entries(params).length
            ? `?${$.param(params).toString()}`
            : "")
      );
    },
    get: (id) => {
      return api.get(`/playlist/${id}`);
    },
    create: (playlist) => {
      return api.post("/playlist/", playlist);
    },
    update: (id, playlist) => {
      return api.put(`/playlist/${id}`, playlist);
    },
    delete: (id) => {
      return api.delete(`/playlist/${id}`);
    },
  },
};
