/**
 * External Dependencies
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

/**
 * Internal Dependencies
 */
import PageContent from "../../components/page-content";
import PageTitle from "../../components/page-title";
import PageWrap from "../../components/page-wrap";
import InformativaContent from "./content";

/**
 * Component
 */
class Informativa extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props);
    return (
      <PageWrap>
        <PageTitle
          breadcrumbs={{
            "/": "Home",
          }}
        >
          <h1>Informativa</h1>
        </PageTitle>
        <PageContent>
          <InformativaContent history={this.props.history} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default withRouter(Informativa);
