/**
 * External Dependencies
 */
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import React, { Component } from "react";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

import Icon from "../../../../components/icon";
/**
 * Internal Dependencies
 */

class TabellaLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "nome", title: "Nome Layout" },
        { name: "orologio", title: "Orologio" },
        { name: "ticker", title: "Testo Scorrevole" },
        { name: "header", title: "Header" },
        { name: "footer", title: "Footer" },
        { name: "data_inserimento", title: "Data Creazione" },
        { name: "opzioni", title: "Opzioni" },
      ],
      rows: [],
      filteringStateColumnExtensions: [
        { columnName: "opzioni", filteringEnabled: false },
      ],
      tableColumnExtension: [
        { columnName: "nome", align: "center" },
        { columnName: "orologio", align: "center" },
        { columnName: "ticker", align: "center" },
        { columnName: "header", align: "center" },
        { columnName: "footer", align: "center" },
        { columnName: "data_inserimento", align: "center" },
        { columnName: "opzioni", align: "center" },
      ],
    };
  }

  eliminaLayout(id, username) {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Vuoi davvero eliminare il layout " + username + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, Elimina!",
      cancelButtonText: "No, Mantieni",
    }).then((result) => {
      if (result.value) {
        let body = {
          id: id,
        };
        window.api.totem.layout
          .elimina(body)
          .then(async (res) => {
            Swal.fire({
              title: "Eliminato!",
              text: "Il layout " + username + " è stato eliminato.",
              icon: "success",
            }).then(() => {
              this.elencoLayout();
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
              "Oops!",
              "C'è stato un problema durante l'eliminazione, riprova più tardi.",
              "error"
            );
          });
      }
    });
  }

  changeFormStatus(id, nome) {
    this.props.status(2, 1, id);
  }

  elencoLayout() {
    window.api.totem.layout
      .tabella("/layout/tabella")
      .then(async (res) => {
        res.data.totem_layout.forEach(async (l) => {
          l.orologio = l.orologio != null ? l.orologio : "❌";
          l.ticker = l.ticker != "0" ? "✔" : "❌";
          l.header = l.header != null ? l.header : "❌";
          l.footer = l.footer != null ? l.footer : "❌";
          l.data_inserimento = moment(l.data_inserimento).format("DD/MM/YYYY");
          l.opzioni = (
            <>
              <Button
                size="sm"
                style={{ marginLeft: "5px" }}
                color="brand"
                className="btn-hover-outline btn-uniform btn-round"
                onClick={() => this.changeFormStatus(l.id, l.nome)}
              >
                <Icon name="edit" />
              </Button>
              <Button
                size="sm"
                style={{ marginLeft: "5px" }}
                color="brand"
                className="btn-hover-outline btn-uniform btn-round"
                onClick={() => this.eliminaLayout(l.id, l.nome)}
              >
                <Icon name="trash" />
              </Button>
            </>
          );
        });
        this.setState({
          rows: res.data.totem_layout,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.elencoLayout();
  }

  render() {
    const {
      rows,
      columns,
      tableColumnExtension,
      filteringStateColumnExtensions,
    } = this.state;

    const {} = this.props;

    return (
      <Paper>
        <Grid rows={rows} columns={columns}>
          <SortingState
            defaultSorting={[{ columnName: "nome", direction: "asc" }]}
          />
          <IntegratedSorting />
          <FilteringState
            // defaultFilters={defaultFilters}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={5} />
          <IntegratedPaging />
          <Table columnExtensions={tableColumnExtension} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow messages={{ filterPlaceholder: "Cerca..." }} />
          <PagingPanel />
        </Grid>
      </Paper>
    );
  }
}

export default TabellaLayout;
